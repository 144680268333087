import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { appointmentsForTodaySelector, appointmentsForThisWeekSelector, unscheduledAppointmentsSelector } from 'selectors/mealPlanning';
import { updateActiveTab, MEAL_PLANNER_TAB_IDS, updateSelectedAppointmentDate } from 'actions/MealPlanning';
import { manualEntriesSelector } from 'selectors/mealPlanning';
import Tabs from 'components/Pasta/Tabs';

const initialTabItems = [
  {
    title: 'Today',
    subtitle: '0 recipes',
    id: MEAL_PLANNER_TAB_IDS.DAY,
  },
  {
    title: 'This Week',
    subtitle: '0 recipes',
    id: MEAL_PLANNER_TAB_IDS.WEEK,
  },
  {
    title: 'Unscheduled',
    subtitle: '0 recipes',
    id: MEAL_PLANNER_TAB_IDS.UNSCHEDULED,
  },
];

const MealPlanTabs = ({
  activeTabID,
  updateActiveTab,
  updateSelectedAppointmentDate,
  appointmentsToday,
  appointmentsThisWeek,
  appointmentsUnscheduled,
  manualEntries,
}) => {
  const [tabItems, setTabItems] = useState(initialTabItems);

  let numAppointmentsThisWeek = 0;

  for (let x = 0; x < appointmentsThisWeek?.length; x++) {
    numAppointmentsThisWeek += appointmentsThisWeek[x].appointments?.length;
  }

  let numUnscheduled = appointmentsUnscheduled?.length;
  const numToday = appointmentsToday?.length;

  if (manualEntries?.length) {
    numUnscheduled += manualEntries.length;
  }

  useEffect(() => {
    const newTabItems = [
      {
        ...tabItems[0],
        subtitle: `${numToday} recipe${numToday !== 1 ? `s` : ``}`,
      },
      {
        ...tabItems[1],
        subtitle: `${numAppointmentsThisWeek} recipe${numAppointmentsThisWeek !== 1 ? `s` : ``}`,
      },
      {
        ...tabItems[2],
        subtitle: `${numUnscheduled} recipe${numUnscheduled !== 1 ? `s` : ``}`,
      },
    ];
    setTabItems(newTabItems);
  }, [appointmentsToday, appointmentsForThisWeekSelector, appointmentsUnscheduled, manualEntries]);

  function onTabClick(tabID) {
    updateActiveTab(tabID);
    updateSelectedAppointmentDate(new Date());
  }

  // Use up-to-date client-time, not server-initialized time
  useEffect(() => {
    updateSelectedAppointmentDate(new Date());
  }, []);

  return (
    <div className="meal-plan-tabs">
      <Tabs
        tabItems={tabItems}
        activeTabID={activeTabID}
        onTabClick={onTabClick}
      />
    </div>
  );
};
MealPlanTabs.propTypes = {
  appointmentsToday: YummlyPropTypes.arrayOfObjects,
  appointmentsThisWeek: YummlyPropTypes.arrayOfObjects,
  appointmentsUnscheduled: YummlyPropTypes.arrayOfObjects,
  activeTabID: YummlyPropTypes.string,
  updateActiveTab: YummlyPropTypes.action,
  updateSelectedAppointmentDate: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    activeTabID: state.mealPlanning?.activeTabID,
    appointmentsToday: appointmentsForTodaySelector(state),
    appointmentsThisWeek: appointmentsForThisWeekSelector(state),
    appointmentsUnscheduled: unscheduledAppointmentsSelector(state),
    manualEntries: manualEntriesSelector(state),
  };
};

const mapDispatchFromProps = {
  updateActiveTab,
  updateSelectedAppointmentDate,
};

export default connect(mapStateToProps, mapDispatchFromProps)(MealPlanTabs);

