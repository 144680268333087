import React from 'react';
import Button from 'components/Pasta/Button';
import { withRouter } from 'react-router-dom';
import * as YummlyPropTypes from "constants/PropTypes";
import { pstIcons } from 'constants/FontFaceCharacters';
import { connect } from 'react-redux';
import { showPopupMenu } from 'actions/App';

const surveyLink = `https://www.surveymonkey.com/r/GMHR56K`;
const helpLink = `/meal-planning/support`;

const MealPlanHelpMenu = ({ history, showPopupMenu }) => {

  const popupOptions = [
    {
      text: 'Meal Planner Help',
      iconCode: pstIcons.F0003_017__Icon_help,
      onClick: onClickedHelp,
    },
    {
      text: 'Give Feedback',
      iconCode: pstIcons.F0003_405__Icon_flag,
      onClick: onClickedGiveFeedback,
    },
  ];

  const btnID = 'meal-plan-help-menu-btn';

  function onClickToggle() {
    showPopupMenu({
      options: popupOptions,
      position: {
        id: btnID,
      },
    });
  }

  function onClickedHelp() {
    history.push(helpLink);
  }

  function onClickedGiveFeedback() {
    window.open(surveyLink);
  }

  return (
    <div className="meal-plan-help-menu">
      <Button aria-label="more options"
        className="options-btn no-padding top-header-button"
        onClick={onClickToggle}
        id={btnID}
        iconCode={pstIcons.F0003_017__Icon_help}
        contained={true}
      />
    </div>
  );
};

MealPlanHelpMenu.propTypes = {
  history: YummlyPropTypes.history,
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchFromProps = {
  showPopupMenu,
};

export default connect(mapStateToProps, mapDispatchFromProps)(withRouter(MealPlanHelpMenu));


