import React, { Component } from 'react';

import { space } from 'constants/SpecialCharacters';
import classNames from 'util/classNames';
import { bindInstance } from 'util/dev';
import { decToFracUI } from 'util/number';

const noFractions = ['g', 'gram', 'grams', 'ml', 'mg'];
const noAbbreviations = ['inch', 'foot'];

class IngredientLine extends Component {
  constructor(props) {
    super(props);
    bindInstance(this);
  }
  handleCheckboxChange() {
    const { handleCheckboxChange } = this.props;

    if (typeof handleCheckboxChange === 'function') {
      handleCheckboxChange(this.props);
    }
  }
  render() {
    const {
      abbreviation,
      pluralAbbreviation,
      amount,
      unit,
      pluralUnit,
      ingredient,
      remainder,
      data,
      decimal,
      showCheckbox,
      checked,
    } = this.props;
    const { isCustomRecipe, displayServings, servings } = data;
    const calculatedAmount =
      noFractions.indexOf(unit && unit.name) < 0
        ? amount / servings * displayServings
        : Math.round(amount / servings * displayServings);
    const fraction = decToFracUI(calculatedAmount, decimal);
    const isSingular = fraction.props?.['data-singular'];
    const displayUnit = noAbbreviations.includes(unit)
      ? isSingular
        ? unit
        : pluralUnit
      : isSingular
        ? abbreviation
        : pluralAbbreviation;
    const clsIngredient = classNames({
      'ingredient': true,
      'force-line-breaks': isCustomRecipe,
    });

    const lineContents = (
      <React.Fragment>
        {amount && (
          <span className="amount">
            {fraction}
            {space}
          </span>
        )}
        {displayUnit && <span className="unit">{displayUnit} </span>}
        {ingredient && (
          <span className={clsIngredient}>{ingredient} </span>
        )}
        {remainder && <span className="remainder">{`(${remainder})`}</span>}
      </React.Fragment>
    );
    return (
      Boolean(amount || displayUnit || ingredient || remainder) && (
        <li className="IngredientLine">
          {showCheckbox
            ? (
              <label>
                <div className="content-wrapper">
                  <span className="line-wrapper">{lineContents}</span>
                  <input
                    type="checkbox"
                    className="rounded-checkbox"
                    checked={checked}
                    onChange={this.handleCheckboxChange}
                  />
                  <div className="checkbox-icon" />
                </div>
              </label>
            ) : lineContents
          }
        </li>
      )
    );
  }
}

IngredientLine.propTypes = {
  abbreviation: YummlyPropTypes.IngredientPropTypes.abbreviation,
  pluralAbbreviation: YummlyPropTypes.IngredientPropTypes.pluralAbbreviation,
  amount: YummlyPropTypes.IngredientPropTypes.amount,
  checked: YummlyPropTypes.bool,
  handleCheckboxChange: YummlyPropTypes.func,
  unit: YummlyPropTypes.IngredientPropTypes.unit,
  pluralUnit: YummlyPropTypes.IngredientPropTypes.unit,
  ingredient: YummlyPropTypes.IngredientPropTypes.ingredient,
  remainder: YummlyPropTypes.IngredientPropTypes.remainder,
  data: YummlyPropTypes.IngredientPropTypes.data,
  decimal: YummlyPropTypes.bool,
  showCheckbox: YummlyPropTypes.bool,
};

export default IngredientLine;
