import React, { Component } from 'react';
import { connect } from 'react-redux';
import { currentUserSelector } from 'selectors/auth';
import { logEvent as logMixpanelEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import cookie from 'util/cookies';
import { SL_TOOLTIP_COOKIE } from 'constants/Cookies';
import { SHOW_SL_PLAN_AND_SHOP_TOOLTIP } from 'constants/index';
import {
  CLEAR_MP_ACTION,
} from 'constants/index';
import { MEAL_PLANNING_TYPE, MEAL_PLANNING_ROUTE } from 'constants/routes';
import { showZendesk, toggleMealPlanClearModal } from 'actions/App';
import MealPlanHelpMenu from 'components/MealPlanHelpMenu';

import { DDE, TRACKING } from 'constants/Tracking';
import { mealItemsSelector } from 'selectors/mealPlanning';
import MealPlanView from 'bundles/mealPlanning/components/MealPlanView';
import { withRouter } from 'react-router-dom';
import { setShoppingListViewType } from 'actions/ShoppingList';
import { updateTooltip } from 'actions/Tooltip';
import { getStringFunction } from 'util/localStrings';
import { strings } from 'constants/MealPlanning';
import { bindInstance } from 'util/dev';
import { deepProperty } from 'util/object';
import MealPlanCarouselCTA from 'components/MealPlanCarouselCTA/MealPlanCarouselCTA';
import { isGrandfatheredUserSelector } from "selectors/auth";
import { isProUser } from "selectors/pro";

const str = getStringFunction(strings, 'mealPlanning');

export class MealPlanning extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      initialState: {
        openedOverflow: false,
        optionsExpanded: false,
      },
    });
  }

  componentDidMount() {
    this.props.showZendesk(false);
    this.props.setShoppingListViewType('recipes');

    if (!cookie.get(SL_TOOLTIP_COOKIE)) {
      cookie.set(SL_TOOLTIP_COOKIE, true);
      this.props.updateTooltip(SHOW_SL_PLAN_AND_SHOP_TOOLTIP, false);
    }
  }

  onUpdateList(e) {
    const action = deepProperty(e, 'currentTarget.dataset.action');
    if (action === CLEAR_MP_ACTION) {
      this.props.toggleMealPlanClearModal(true);
    }
  }

  navigateBack() {
    this.props.history.goBack();
  }

  clickPrint() {
    const { logDDEEvent, logMixpanelEvent } = this.props;
    const url = `${MEAL_PLANNING_ROUTE}?viewType=${MEAL_PLANNING_TYPE}#print`;
    window.open(url);
    logMixpanelEvent(TRACKING.SHARE, {
      'Share Type': TRACKING.PRINT,
      'Share Content Type': TRACKING.MEAL_PLAN,
    });
    logDDEEvent(DDE.SHARE, {
      share_type: TRACKING.PRINT,
      content_type: TRACKING.MEAL_PLAN_CONTENT_TYPE,
    });
  }

  clickEmail() {
    const { logDDEEvent, logMixpanelEvent } = this.props;
    logMixpanelEvent(TRACKING.SHARE, {
      'Share Type': TRACKING.EMAIL,
      'Share Content Type': TRACKING.MEAL_PLAN,
    });
    logDDEEvent(DDE.SHARE, {
      share_type: TRACKING.EMAIL,
      content_type: TRACKING.MEAL_PLAN_CONTENT_TYPE,
    });
  }

  render() {
    const { currentUser, isProUser } = this.props;

    return (
      <div className="meal-planning">
        <div className="flex meal-planning-spacing meal-planning-header">
          <h2 className="meal-planning-title pst-h2">
            {str('title')}
          </h2>
          <div>
            <div className="top-header-overflow-wrapper" >
              <MealPlanHelpMenu/>
            </div>
          </div>
        </div>

        { !currentUser || (currentUser && !isProUser)
          ? <MealPlanCarouselCTA />
          : <MealPlanView />
        }
      </div>
    );
  }
}

MealPlanning.propTypes = {
  currentUser: YummlyPropTypes.currentUser,
  history: YummlyPropTypes.history,
  logDDEEvent: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  setShoppingListViewType: YummlyPropTypes.action,
  showZendesk: YummlyPropTypes.action,
  toggleMealPlanClearModal: YummlyPropTypes.action,
  updateTooltip: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    isProUser: isProUser(state) || isGrandfatheredUserSelector(state),
    currentUser: currentUserSelector(state),
    meals: mealItemsSelector(state),
    previousLocation: state.app.previousLocation,
  };
};

const mapDispatchToProps = {
  logDDEEvent,
  logMixpanelEvent,
  setShoppingListViewType,
  showZendesk,
  toggleMealPlanClearModal,
  updateTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MealPlanning));
