import React, { Component } from 'react';
import { connect } from 'react-redux';

import { icons } from 'constants/FontFaceCharacters';
import { DEBOUNCE_SOCIAL } from 'constants/index';
import { PLATFORM_DESKTOP } from 'util/windowBreakpoints';
import { bindInstance } from 'util/dev';
import { REVIEW_FLAG, REVIEW_HELPFUL } from 'actions/Recipe';

class ReviewSocialActions extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this, {
      initialState: {
        showFlagPopup: false,
      },
      debounce: {
        handleLike: DEBOUNCE_SOCIAL,
      },
    });
  }

  getCurrentlyShownReviewCount() {
    const { recipe } = this.props;

    let numReviews = (recipe.reviews || []).length;

    if (recipe.rating && recipe.rating.myReview) {
      numReviews++;
    }

    return numReviews;
  }

  handleLike() {
    const { review, recipe, likeActionReview, deleteActionReview } = this.props;
    if (review && (review.thisUserActions || []).indexOf(REVIEW_HELPFUL) > -1) {
      deleteActionReview(
        review.id,
        REVIEW_HELPFUL,
        recipe.globalId,
        this.getCurrentlyShownReviewCount()
      );
    } else {
      likeActionReview(
        review,
        REVIEW_HELPFUL,
        recipe,
        this.getCurrentlyShownReviewCount()
      );
    }
  }

  handleFlag(e) {
    const { review, recipe, likeActionReview, deleteActionReview } = this.props;
    const flagReason = e.target.getAttribute('data-flag-reason');
    if (review && (review.thisUserActions || []).indexOf(REVIEW_FLAG) > -1) {
      deleteActionReview(
        review.id,
        REVIEW_FLAG,
        recipe.globalId,
        this.getCurrentlyShownReviewCount(),
        flagReason
      );
    } else {
      likeActionReview(
        review,
        REVIEW_FLAG,
        recipe,
        this.getCurrentlyShownReviewCount(),
        flagReason
      );
    }
    this.setState({
      showFlagPopup: false,
    });
  }

  handleFlagPopup() {
    this.setState({
      showFlagPopup: !this.state.showFlagPopup,
    });
  }

  render() {
    const { review, windowDetails } = this.props;
    const likeTooltip = {};
    const flagTooltip = {};

    if (windowDetails.platform === PLATFORM_DESKTOP) {
      likeTooltip.title = 'Like this review';
      flagTooltip.title = 'Flag this review for attention';
    }

    return (
      <div className="review-social-actions">
        <span
          className={
            'y-icon helpful-icon' +
            (review && (review.thisUserActions || []).indexOf(REVIEW_HELPFUL) > -1
              ? ' user-action'
              : '')
          }
          data-icon={icons.thumbsUp}
          onClick={this.handleLike}
          {...likeTooltip}
        />
        {review &&
          review.helpfulCount > 0 && (
          <span className="helpful-count">{review.helpfulCount}</span>
        )}
        <span className="divider" />
        <span
          className={
            'y-icon flag-icon' +
            (review && (review.thisUserActions || []).indexOf(REVIEW_FLAG) > -1
              ? ' user-action'
              : '')
          }
          data-icon={icons.flag}
          onClick={
            review && (review.thisUserActions || []).indexOf(REVIEW_FLAG) > -1
              ? this.handleFlag
              : this.handleFlagPopup
          }
          {...flagTooltip}
        />
        <div
          className={'flag-options' + (this.state.showFlagPopup ? ' show' : '')}
          onBlur={this.handleFlagPopup}
        >
          <ul>
            <li onClick={this.handleFlag} data-flag-reason="spam">
              {'SPAM (not the meat)'}
            </li>
            <li onClick={this.handleFlag} data-flag-reason="off-topic">
              {'Off the menu (irrelevant)'}
            </li>
            <li onClick={this.handleFlag} data-flag-reason="inappropriate">
              {'Tasteless (inappropriate)'}
            </li>
          </ul>
        </div>
        {review &&
          review.flagCount > 0 && (
          <span className="flag-count">{review.flagCount}</span>
        )}
      </div>
    );
  }
}

ReviewSocialActions.propTypes = {
  deleteActionReview: YummlyPropTypes.action,
  likeActionReview: YummlyPropTypes.action,
  recipe: YummlyPropTypes.recipe,
  review: YummlyPropTypes.review,
  windowDetails: YummlyPropTypes.windowDetails,
};

const mapStateToProps = (state) => {
  return {
    windowDetails: state.windowDetails,
  };
};

export default connect(mapStateToProps)(ReviewSocialActions);
