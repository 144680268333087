import React, { Component } from 'react';
import { connect } from 'react-redux';

import { icons } from 'constants/FontFaceCharacters';
import { bindInstance } from 'util/dev';
import cookie from 'util/cookies';
import { SL_ADD_INGREDIENT_TOOLTIP_COOKIE } from 'constants/Cookies';
import { logEvent } from 'actions/mixpanel';
import { TRACKING } from 'constants/Tracking';
import { updateTooltip } from 'actions/Tooltip';
import { SHOW_SL_ADD_INGREDIENT_TOOLTIP } from 'constants/index';
import { currentUserSelector } from 'selectors/auth';

export class ShoppingListAddIngredientTooltip extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      initialState: {
        showTooltip: !props.showSLPlanAndShopTooltip && !cookie.get(SL_ADD_INGREDIENT_TOOLTIP_COOKIE),
      },
    });
    this.tracked = false;
  }
  componentDidMount() {
    if (!this.isTracked && this.state.showTooltip) {
      this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.SHOPPING_LIST_ADD_INGREDIENT_TOOLTIP,
      });
      this.isTracked = true;
    }
  }
  componentDidUpdate(prevProps) {
    const { isLoggedIn: currLoggedIn } = this.props;
    const prevLoggedIn = prevProps.isLoggedIn;
    if (!this.isTracked && currLoggedIn && prevLoggedIn !== currLoggedIn && this.state.showTooltip) {
      this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.SHOPPING_LIST_ADD_INGREDIENT_TOOLTIP,
      });
      this.isTracked = true;
    }
  }
  componentWillUnmount() {
    if (!this.props.showSLPlanAndShopTooltip) {
      this.setCookieAndStore();
    }
  }
  onClose() {
    this.setState({
      showTooltip: false,
    });
    this.setCookieAndStore();
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.SHOPPING_LIST_ADD_INGREDIENT_TOOLTIP,
      'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
    });
  }
  onGotIt() {
    this.setState({
      showTooltip: false,
    });
    this.setCookieAndStore();
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.SHOPPING_LIST_ADD_INGREDIENT_TOOLTIP,
      'Prompt Action': TRACKING.GOT_IT,
    });
  }
  setCookieAndStore() {
    this.props.updateTooltip(SHOW_SL_ADD_INGREDIENT_TOOLTIP, false);
    cookie.set(SL_ADD_INGREDIENT_TOOLTIP_COOKIE, true);
  }
  render() {
    if (this.props.showSLPlanAndShopTooltip || !this.state.showTooltip || this.props.hasModalOpen) {
      return null;
    }
    return (
      <div className="shopping-list-add-ingredient-tooltip">
        <span className="new-flag font-bold greyscale-3">{'ADD INGREDIENT'}</span>
        <h5 className="tooltip-title font-bold h5-text">{'Make your list'}</h5>
        <p className="p3-text greyscale-2">{'Manually add items to your list here, or select ingredients from any recipe page.'}</p>
        <span
          className="y-icon close-btn greyscale-3"
          data-icon={icons.xLarge}
          onClick={this.onClose}
        />
        <span className="font-bold p3-text primary-teal got-it-btn" onClick={this.onGotIt} >{'Got it'}</span>
      </div>
    );
  }
}

ShoppingListAddIngredientTooltip.propTypes = {
  logMixpanelEvent: YummlyPropTypes.action,
  showSLPlanAndShopTooltip: YummlyPropTypes.bool,
  updateTooltip: YummlyPropTypes.action,
  isLoggedIn: YummlyPropTypes.bool,
  hasModalOpen: YummlyPropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    showSLPlanAndShopTooltip: state.tooltip.showSLPlanAndShopTooltip,
    isLoggedIn: Boolean(currentUserSelector(state)),
    hasModalOpen: Boolean(state.app.showModal?.modalType),
  };
};

const mapDispatchToProps = {
  logMixpanelEvent: logEvent,
  updateTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListAddIngredientTooltip);
