// P0013 • Tabs
import React from 'react';

import classNames from 'util/classNames';

const Tabs = ({
  tabItems,
  activeTabID,
  onTabClick,
}) => {
  return <div className="tabs-root flex">
    {tabItems?.map((tabItemDetails) => {
      return <TabItem
        key={tabItemDetails.id}
        tabItemDetails={tabItemDetails}
        currentlyActive={activeTabID === tabItemDetails.id}
        onTabClick={onTabClick}
      />;
    })}
  </div>;
};

const TabItem = ({
  tabItemDetails,
  currentlyActive,
  onTabClick,
}) => {
  const tabItemClassNames = classNames({
    'tab-item': true,
    'cursor-pointer': true,
    active: currentlyActive,
  });

  function onClicked() {
    if (onTabClick) {
      onTabClick(tabItemDetails.id);
    }
  }

  return <div className={tabItemClassNames} onClick={onClicked}>
    <div className="tab-title pst-h7">
      {tabItemDetails.title}
    </div>
    <div className="tab-subtitle greyscale-2 pst-p-small-regular">
      {tabItemDetails.subtitle}
    </div>
  </div>;
};

TabItem.propTypes = {
  tabItemDetails: YummlyPropTypes.objectOrString,
  currentlyActive: YummlyPropTypes.bool,
  onTabClick: YummlyPropTypes.func,
};

Tabs.propTypes = {
  tabItems: YummlyPropTypes.arrayOfObjects,
  activeTabID: YummlyPropTypes.string,
  onTabClick: YummlyPropTypes.func,
};

export default Tabs;