import React from 'react';
import MealPlanRecipeCard from 'bundles/mealPlanning/components/MealPlanRecipeCard';
import MealPlanLinkCard from 'bundles/mealPlanning/components/MealPlanLinkCard';
import MealPlanManualCard from 'bundles/mealPlanning/components/MealPlanManualCard';

import { ITEM_TYPE_KEY, ITEM_TYPE_RECIPE, RECIPE_DATA_KEY } from 'constants/MealPlanning';

function MealPlanCardEntity(props) {
  const {
    isInTray,
    meal,
    popupOpen,
    setPopup,
  } = props;

  if (meal[ITEM_TYPE_KEY] === ITEM_TYPE_RECIPE || meal[RECIPE_DATA_KEY]) {
    return <MealPlanRecipeCard isInTray={isInTray} meal={meal} popupOpen={popupOpen} setPopup={setPopup} />;
  } else if (meal.url) {
    return <MealPlanLinkCard isInTray={isInTray} meal={meal} />;
  } else if (meal.title) {
    return <MealPlanManualCard isInTray={isInTray} meal={meal} />;
  }

  return null;
}

MealPlanCardEntity.propTypes = {
  isInTray: YummlyPropTypes.bool,
  meal: YummlyPropTypes.meal,
  popupOpen: YummlyPropTypes.bool,
  setPopup: YummlyPropTypes.action,
};

export default MealPlanCardEntity;
