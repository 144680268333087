import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  deleteActionReview,
  likeActionReview,
} from 'actions/Recipe';

import Button from 'components/Button';
import Image from 'components/Image';
import Link from 'components/Link';
import ReviewActions from 'components/ReviewActions';
import ReviewSocialActions from 'components/ReviewSocialActions';
import StarRating from 'components/StarRating';

import { icons } from 'constants/FontFaceCharacters';
import { MIN_REVIEW_LENGTH, MAX_REVIEW_LENGTH } from 'constants/index';

import { PROFILE_ROUTE } from 'constants/routes';

import cdn from 'util/cdn';
import classNames from 'util/classNames';
import { bindInstance } from 'util/dev';
import { getStringFunction } from 'util/localStrings';
import { AMP_PAGE, MAKE_MODE_PAGE, MEAL_PLANNING_PAGE } from 'util/location';
import { elapsedTimestamp, enforceUTC } from 'util/timestamp';

const defaultPictureUrl = `${cdn.imagePrefix()}/avatar.png`;
const PROFILE_PICTURE_SIZE = 56;
const TEXT_STATE = 'text';
const INPUT_STATE = 'input';

export const str = getStringFunction(
  [
    {
      id: 'review.defaultReviewerName',
      defaultMessage: 'Yummly User',
    },
    {
      id: 'review.loggedOutBold',
      defaultMessage: 'Connect to Yummly',
    },
    {
      id: 'review.loggedOut',
      defaultMessage: 'to review this recipe',
    },
    {
      id: 'review.newReview',
      defaultMessage: 'Write your review or comment here',
    },
    {
      id: 'review.newDirectionsReview',
      defaultMessage: 'How did it turn out? (Required)',
    },
    {
      id: 'review.lengthError',
      defaultMessage: `Your review is too short and leaves us hungry for more. We require at least ${MIN_REVIEW_LENGTH} characters.`,
    },
    {
      id: 'review.lengthMaxError',
      defaultMessage: `Your review is too long. Please limit it to ${MAX_REVIEW_LENGTH} characters.`,
    },
    {
      id: 'review.ratingError',
      defaultMessage:
        'How would you rate this recipe? Please add a star rating before submitting your review.',
    },
  ],
  'review'
);

class Review extends Component {
  constructor() {
    super(...arguments);
    let stars;
    if (this.props.initialStars) {
      stars = Number(this.props.initialStars);
    } else if (this.props.review) {
      stars = Number(this.props.review.rating);
    } else {
      stars = 0;
    }

    bindInstance(this, {
      initialState: {
        type: 'text',
        new: this.props.new,
        currentText: (this.props.review && this.props.review.text) || '',
        stars,
        error: '',
        review: this.props.review,
      },
    });
    this.isAmp = this.props.pageLocations[AMP_PAGE];
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.review !== prevState.review) {
      return {
        review: props.review,
        currentText: (props.review && props.review.text) || '',
        type: 'text',
      };
    }

    return null;
  }

  setErrorState(error) {
    this.setState({
      error,
    });
  }

  setTypeState(type) {
    this.setState({
      type,
    });
  }

  handleClickOpen(e) {
    e.preventDefault();
    if (this.props.auth.currentUser) {
      this.setState({
        type: INPUT_STATE,
      });
      if (this.props.openCallback) {
        this.props.openCallback(true);
      }
    } else {
      const url = '/login?entry=' + encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search);
      this.props.history.push(url);
    }
  }

  handleClickClose() {
    this.setState({
      type: TEXT_STATE,
      currentText: (this.props.review && this.props.review.text) || '',
      stars: (this.props.review && this.props.review.rating) || 0,
      error: '',
    });
    if (this.props.openCallback) {
      this.props.openCallback(false);
    }
    if (__CLIENT__) {
      document.querySelector('textarea').removeAttribute('style');
    }
  }

  handleRef(ref) {
    this.textarea = ref;
  }

  handleOnChange() {
    if (!this.props.pageLocations[MEAL_PLANNING_PAGE] && !this.props.staticOnInput) {
      this.textarea.style.minHeight = '10em';
      this.textarea.style.overflow = 'auto';
    }

    this.setState({
      currentText: this.textarea.value,
    });
    if (
      this.state.error === str('lengthError') &&
      this.textarea.value.length >= MIN_REVIEW_LENGTH
    ) {
      this.setState({
        error: '',
      });
    }
  }

  handleStarRating(e) {
    const starRating =
      parseInt(e.target.getAttribute('data-star-number'), 10) || 0;

    this.setState({
      stars: starRating,
      error:
        this.state.error === str('ratingError') && starRating > 0
          ? ''
          : this.state.error,
    });
  }

  useFallbackImage(e) {
    if (e.target.src !== defaultPictureUrl) {
      e.target.src = defaultPictureUrl;
    }
  }

  profileInformation(canLinkify = true, fallbackUser) {
    const { review } = this.props;
    const { user } = review || {};
    const { firstName = '', lastName = '', userName, pictureUrl } =
      user || fallbackUser || {};
    let link, name;
    let nameComponent;
    name = str('defaultReviewerName');

    if (firstName || lastName) {
      let lastInitial = '';

      if (lastName) {
        lastInitial = lastName[0] + '.';
      }

      name = [firstName, lastInitial].join(' ').trim();
    }

    const linkUrl = userName && `${PROFILE_ROUTE}/${encodeURIComponent(userName)}`;
    const onError = {};
    if (!this.isAmp) {
      onError['onError'] = this.useFallbackImage;
    }

    link = (
      <Image
        alt="Reviewer Profile Image"
        src={pictureUrl}
        size={PROFILE_PICTURE_SIZE}
        mode="crop"
        defaultImage={defaultPictureUrl}
        {...onError}
        width="56px"
        height="56px"
      />
    );

    if (canLinkify && linkUrl) {
      nameComponent = <Link
        href={linkUrl}
        title={`${name}'s profile`}>
        {name}
      </Link>;
      link = <Link
        href={linkUrl}
        title={`${name}'s profile`}>
        {link}
      </Link>;
    }

    return {
      link,
      name: nameComponent || name,
    };
  }

  render() {
    let reviewContent, profileInformation, link, name;
    const {
      likeActionReview,
      deleteActionReview,
      recipe,
      review,
      auth,
      pageLocations,
    } = this.props;
    const { error, currentText, stars } = this.state;
    let type = this.state.type;
    if (this.props.alwaysOpen) {
      type = 'input';
    }
    const cls = classNames({
      'review media': true,
      'new-review': this.props.new,
      'edit-review': type === INPUT_STATE,
    });

    const errors = <div className="review-errors">{error}</div>;

    const { user, createTime } = review || {};
    const isRender = !(this.props.new && this.isAmp);

    const isCurrentUser =
      auth &&
      auth.currentUser &&
      user &&
      auth.currentUser.userName === user.userName;
    if (!auth.currentUser && this.props.new) {
      profileInformation = this.profileInformation();
      link = profileInformation.link;

      reviewContent = <div className="review-content">
        <Link className="review-text font-normal p2-text" onClick={this.handleClickOpen}>
          <strong>{str('loggedOutBold')}</strong> {str('loggedOut')}
        </Link>
      </div>;
    } else if (this.props.new) {
      profileInformation = this.profileInformation(false, auth && auth.currentUser);
      link = profileInformation.link;
      name = profileInformation.name;

      reviewContent = <div className="review-content">
        {type === INPUT_STATE &&
          <React.Fragment>
            <div className={classNames({
              'review-name font-bold': true,
              'show': type === INPUT_STATE,
            })}>
              {name}
            </div>
            <div
              className={classNames({
                'review-rating': true,
                'show': type === INPUT_STATE,
              })}
              onClick={this.handleStarRating}
            >
              <StarRating score={stars} tooltip={true} includeTracking={true} recipe={recipe} />
            </div>
          </React.Fragment>
        }
        <textarea
          className={'review-text font-normal p2-text' + (type === INPUT_STATE ? ' expanded' : '')}
          placeholder={str('newReview')}
          aria-label={str('newReview')}
          onClick={this.handleClickOpen}
          onChange={this.handleOnChange}
          value={currentText}
          readOnly={!auth.currentUser}
          ref={this.handleRef}
        />
        {type === INPUT_STATE &&
          <div className="review-errors font-bold p3-text">{error}</div>
        }
        {type === INPUT_STATE &&
          <ReviewActions
            recipe={recipe}
            directionsReview={true}
            new={true}
            currentText={currentText}
            stars={stars}
            setErrorState={this.setErrorState}
            setTypeState={this.setTypeState}
            handleClickClose={this.handleClickClose}
            openCallback={this.props.openCallback}
            onDeleteCallback={this.props.onDeleteCallback}
            onSubmitCallback={this.props.onSubmitCallback}
            onCancelCallback={this.props.onCancelCallback}
          />
        }
      </div>;

    } else if (type === TEXT_STATE) {
      profileInformation = this.profileInformation(true);
      link = profileInformation.link;
      name = profileInformation.name;

      reviewContent = (
        <div className="review-content">
          <div className="review-name">
            {name} <span className="font-bold greyscale-3 p3-text">{elapsedTimestamp(enforceUTC(createTime))}</span>
          </div>
          <div className="review-rating">
            <StarRating score={stars} recipe={recipe} />
            {
              !this.isAmp &&
              auth &&
              auth.currentUser &&
              !isCurrentUser &&
              <ReviewSocialActions
                deleteActionReview={deleteActionReview}
                likeActionReview={likeActionReview}
                review={review}
                recipe={recipe} />
            }
          </div>
          {Boolean(!this.isAmp && isCurrentUser && !pageLocations[MAKE_MODE_PAGE]) && (
            <Button className="edit-button" onClick={this.handleClickOpen}>
              <span className="y-icon" data-icon={icons.pencil} />
              {'Edit'}
            </Button>
          )}
          <div className="review-text font-normal p2-text">{currentText}</div>
          {Boolean(pageLocations[MAKE_MODE_PAGE]) && (
            <React.Fragment>
              <Button className="btn-secondary make-mode-edit-review" onClick={this.handleClickOpen}>
                {'Edit Review'}
              </Button>
              <Link className="btn-wire make-mode-exit-review" href={`/recipe/${recipe.id}`}>
                {'Done'}
              </Link>
            </React.Fragment>
          )}
        </div>
      );
    } else if (type === INPUT_STATE) {
      profileInformation = this.profileInformation(true);
      link = profileInformation.link;
      name = profileInformation.name;

      reviewContent = (
        <div className="review-content">
          <div className="review-name">{name}</div>
          <div className="review-rating" onClick={this.handleStarRating}>
            <StarRating score={stars} tooltip={true} includeTracking={true} recipe={recipe} />
          </div>
          <textarea
            className={
              'review-text font-normal p2-text' + (type === INPUT_STATE ? ' expanded' : '')
            }
            onChange={this.handleOnChange}
            value={currentText}
            ref={this.handleRef}
          />
          {errors}
          <ReviewActions
            recipe={recipe}
            review={review}
            currentText={currentText}
            stars={stars}
            setErrorState={this.setErrorState}
            setTypeState={this.setTypeState}
            handleClickClose={this.handleClickClose}
            openCallback={this.props.openCallback}
            onDeleteCallback={this.props.onDeleteCallback}
            onSubmitCallback={this.props.onSubmitCallback}
            onCancelCallback={this.props.onCancelCallback}
          />
        </div>
      );
    }

    const reviewProps = {};
    if (review) {
      reviewProps.id = review.id;
    }

    return isRender && (
      <div className={cls} {...reviewProps}>
        {link}
        {reviewContent}
      </div>
    );
  }
}

Review.propTypes = {
  alwaysOpen: YummlyPropTypes.bool,
  auth: YummlyPropTypes.auth,
  deleteActionReview: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
  initialStars: YummlyPropTypes.number,
  likeActionReview: YummlyPropTypes.action,
  new: YummlyPropTypes.bool,
  onCancelCallback: YummlyPropTypes.func,
  onDeleteCallback: YummlyPropTypes.func,
  onSubmitCallback: YummlyPropTypes.func,
  openCallback: YummlyPropTypes.func,
  pageLocations: YummlyPropTypes.pageLocations,
  recipe: YummlyPropTypes.recipe,
  review: YummlyPropTypes.review,
  staticOnInput: YummlyPropTypes.bool,
};

const mapStateToProps = state => {
    return {
      auth: state.auth,
      pageLocations: state.pageLocations,
    };
  },
  mapDispatchToProps = {
    deleteActionReview,
    likeActionReview,
  };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Review));
