import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addScheduleIt,
  SCHEDULE_IT,
  setMadeIt,
  deleteMeals,
  toggleManualEntryModal,
  toggleRemoveModal,
} from 'actions/MealPlanning';
import { hasGuidedContent, getRecipeTrackingIds } from 'actions/helpers';
import { logDDEEvent } from 'actions/DDE';
import { logEvent } from 'actions/mixpanel';
import { showingModal } from 'actions/Modal';
import { toggleIngredientsListModal } from 'actions/App';

import { DDE, TRACKING } from 'constants/Tracking';

import { MADE_KEY, ITEM_TYPE_KEY, ITEM_TYPE_MANUAL, RECOGNIZED_URL, TRACKING_ITEM_ID } from 'constants/MealPlanning';

import Button from 'components/Button';
import ClickAwayListener from 'components/ClickAwayListener';
import Icon from 'components/Pasta/Icon';
import Image from 'components/Image';
import Link from 'components/Link';
import Review from 'components/Review';
import ShoppingListIngredients from 'components/ShoppingListIngredients';
import StarRating from 'components/StarRating';

import { bindInstance } from 'util/dev';
import { BREAKPOINT_SMALL_TRAY } from 'util/windowBreakpoints';
import { convertTotalTime } from 'util/number';
import { deepProperty } from 'util/object';
import classNames from 'util/classNames';
import storage from 'util/storage';
import { RECIPE_DATA_KEY } from 'constants/MealPlanning';

const cardStates = {
  initial: 'initial',
  review: 'review',
  submittedReview: 'submittedReview',
  done: 'done',
};

const mobileDeleteId = 'mobileDelete';

const componentInitialState = {
  openedIngredientsPopup: false,
  cardState: cardStates.initial,
  clickedStarRating: 0,
  mobileOptionsExpanded: false,
  scheduleItState: {},
};

export class MealPlanRecipeCard extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      initialState: {
        ...componentInitialState,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cardState !== cardStates.submittedReview && this.state.cardState === cardStates.submittedReview) {
      this.transitionTimeout = setTimeout(() => {
        this.transitionState(cardStates.done);
      }, 5000);
    }

    if (prevState.cardState !== cardStates.done && this.state.cardState === cardStates.done) {
      this.transitionTimeout = setTimeout(() => {
        this.transitionState(cardStates.initial);
      }, 1000);
    }

    if (prevProps.meal !== this.props.meal) {
      if (prevProps.meal.id !== this.props.meal.id) {
        // meal changed, reset card state
        clearTimeout(this.transitionTimeout);
        // eslint-disable-next-line
        return this.setState({
          ...componentInitialState,
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transitionTimeout);
  }

  onMealPlanCardClick(e) {
    if (this.state.mobileOptionsExpanded && e.target && e.target.id !== mobileDeleteId) {
      this.setState({
        mobileOptionsExpanded: false,
      });
    }
  }

  onMobileModalScroll(e) {
    e.stopPropagation();
  }

  onIngredientPopupBlur(e) {
    if (this.props.popupOpen) {
      this.closePopup(e);
    }
  }

  setMadeIt(e) {
    e.preventDefault();
    if (this.props.meal) {
      this.props.setMadeIt(this.props.meal);
      if (!this.props.meal[MADE_KEY]) {
        this.props.toggleRemoveModal(true, this.props.meal);
      }
    }
  }

  toggleScheduleIt(e) {
    const val = storage.perm.get(SCHEDULE_IT);
    const { recipe, logEvent, logDDEEvent } = this.props,
      { id } = recipe,
      recipeSource = deepProperty(recipe, 'source.sourceDisplayName');

    const isScheduled = val && val[id];

    if (isScheduled) {
      e.preventDefault();
    }

    const scheduleItState = isScheduled
      ? {} // empty state
      : addScheduleIt(id);

    if (!isScheduled) {

      logEvent(TRACKING.SCHEDULE_IT, {
        'Content ID': id,
        'Author': recipeSource,
        'Content Type': 'recipe',
      });

      logDDEEvent(DDE.SCHEDULE_IT, {
        content_id: id,
        content_type: 'recipe',
      });

    }

    this.setState({
      scheduleItState,
    });
  }

  logCardClick() {
    const recipe = this.props.recipe;
    const recipeId = recipe.id;
    this.props.logDDEEvent(DDE.CARD_CLICK, {
      content_id: recipeId,
      content_type: 'recipe',
      pro_content: recipe.isProRecipe,
      guided_content: hasGuidedContent(recipe),
      ...getRecipeTrackingIds(recipe),
    });
  }

  togglePopup(e) {
    e.preventDefault();
    this.props.toggleIngredientsListModal(true);
    this.props.setPopup(this.props.meal.id);
    this.props.showingModal(true);
  }

  toggleMobileOptions(e) {
    e.preventDefault();
    if (this.state.mobileOptionsExpanded) {
      this.props.deleteMeals({
        meals: [this.props.meal],
        trackingProps: {
          ddeRemoveType: DDE.MEAL_PLAN_LIST,
          mpRemoveType: TRACKING.MEAL_PLAN_TYPE_PAGE,
        },
      });
    }
    this.setState({
      mobileOptionsExpanded: !this.state.mobileOptionsExpanded,
    });
  }

  closePopup(e) {
    e.preventDefault();
    this.props.toggleIngredientsListModal(false);
    this.props.setPopup(false);
    this.props.showingModal(false);
  }

  deleteMeal(e, trackingProps) {
    e.preventDefault();
    this.props.deleteMeals({
      meals: [this.props.meal],
      trackingProps,
    });
  }

  reviewCancelCallback() {
    this.transitionState(cardStates.initial);
  }

  reviewSubmitCallback() {
    this.transitionState(cardStates.submittedReview);
  }

  reviewDeleteCallback() {
    this.transitionState(cardStates.initial);
  }

  // presumably only fires when they press Keep in Plan for now
  transitionToDone() {
    const {
      recipe,
      meal,
    } = this.props;
    const recipeSource = deepProperty(recipe, 'source.sourceDisplayName');
    const mixpanelProps = {
      'Content ID': recipe.id,
      'Author': recipeSource,
      'Content Type': 'recipe',
    };
    const ddeProps = {
      'content_id': recipe.id,
      'content_type': 'recipe',
      'pro_content': recipe.isProRecipe,
      'guided_content': hasGuidedContent(recipe),
      ...getRecipeTrackingIds(recipe),
    };

    if (meal[ITEM_TYPE_KEY] === ITEM_TYPE_MANUAL) {
      mixpanelProps.meal_plan_manual_recipe_type = RECOGNIZED_URL;
      ddeProps.meal_plan_manual_recipe_type = RECOGNIZED_URL;
      mixpanelProps[TRACKING_ITEM_ID] = meal.id;
      ddeProps[TRACKING_ITEM_ID] = meal.id;
    }

    this.props.logEvent('Meal Plan Keep in Plan', mixpanelProps);

    this.props.logDDEEvent(DDE.MEAL_PLAN_KEEP_AFTER_REVIEW, ddeProps);

    this.transitionState(cardStates.done);
  }

  deleteFromIcon(e) {
    const trackingProps = {
      ddeRemoveType: DDE.MEAL_PLAN_LIST,
      mpRemoveType: TRACKING.MEAL_PLAN_TYPE_PAGE,
    };
    this.deleteMeal(e, trackingProps);
  }

  deletePostReview(e) {
    this.deleteMeal(e, {
      mpRemoveType: TRACKING.MEAL_PLAN_REMOVE_TYPE_REVIEW,
      ddeRemoveType: DDE.MEAL_PLAN_REMOVE_TYPE_REVIEW,
    });
  }

  openReviewStep(rating) {
    this.setState({
      cardState: cardStates.review,
      clickedStarRating: rating,
    });
  }

  openManualEntryModal() {
    this.props.toggleManualEntryModal(true, this.props.meal);
  }
toggleScheduleModal
transitionState(state) {
  if (this.transitionTimeout) {
    clearTimeout(this.transitionTimeout);
  }

  this.setState({
    cardState: state,
  });
}

render() {
  const { meal, isInTray, recipe } = this.props;
  if (!recipe) {
    return null;
  }
  const recipeTime = convertTotalTime(recipe.totalTimeSec);
  let recipeUrl = `/recipe/${recipe.id}`;

  if (meal['recipe-serving-size']) {
    recipeUrl += `?servings=${meal['recipe-serving-size']}`;
  }

  const myReview = deepProperty(recipe, 'rating.myReview');
  const tinyCardMode = this.props.windowDetails.windowWidth < BREAKPOINT_SMALL_TRAY;
  const cardState = this.state.cardState;
  const classes = classNames({
    'meal-plan-card': true,
    [`state-${this.state.cardState}`]: true,
    'recipe-made': meal[MADE_KEY],
    'is-in-tray': Boolean(isInTray),
  });

  const mobileOptionsClasses = classNames({
    'mobile-options': true,
    'show-delete': this.state.mobileOptionsExpanded,
  });

  const madeItClasses = classNames({
    'made-it-wrapper cursor-pointer': true,
    [MADE_KEY]: meal[MADE_KEY],
  });

  const submittedReviewBlurb = <div className="card-content">
    <Icon className="checkmark-submitted" iconName="checkSmall" />
    <div className="submitted-description primary-dark p1-text font-bold">{'Your review has been submitted'}</div>
    <div className="submitted-actions">
      <Button className="btn-primary keep-action submit-action" onClick={this.transitionToDone}>{'Keep in Meal Plan'}</Button>
      <Button className="primary-teal delete-action submit-action" onClick={this.deletePostReview}>{'Remove from Plan'}</Button>
    </div>
  </div>;

  const showRating = meal[MADE_KEY];
  const showEditModal = meal[ITEM_TYPE_KEY] === ITEM_TYPE_MANUAL;

  return (
    <div className={classes} onClick={this.onMealPlanCardClick}>
      <Link href={recipeUrl} target="_blank" onClick={this.logCardClick} title={recipe.name}>
        <Image src={recipe.image} size={320} mode="crop" className="meal-plan-card-image" />
      </Link>
      { ([cardStates.initial, cardStates.done].includes(cardState) ||
            (cardStates.review === cardState && tinyCardMode)) &&
          <div className="card-content">
            <Link href={recipeUrl} target="_blank" className="h4-text font-bold primary-dark card-title">{recipe.name}</Link>
            <div className="card-descriptions">
              <span className="p2-text greyscale-1 font-normal time-description">{`${recipeTime.value} ${recipeTime.unit}`}</span>
              <span className="pipe-divider greyscale-4"/>
              <span className="p2-text greyscale-1 font-normal ingredient-description">
                <span className="popup-toggler" role="button" onClick={this.togglePopup}>
                  {`${deepProperty(recipe, 'ingredientLines.imperial', []).length} Ingredients `}
                  <Icon iconName="downArrowSmall" className="toggler-icon" />
                </span>
                { this.props.popupOpen &&
                    <div className="ingredient-popup background-light">
                      <div className="popup-overlay">
                        <ClickAwayListener onClickAway={this.onIngredientPopupBlur}>
                          <div className="popup-wrapper" onScroll={this.onMobileModalScroll}>
                            <Icon className="close-popup greyscale-3" iconName="xMedium" onClick={this.closePopup} />
                            <div className="font-bold h4-text primary-dark popup-title">{'Ingredients'}</div>
                            <div className="font-bold greyscale-1 popup-servings micro-caps">{`Servings: ${recipe.displayServings}`}</div>
                            <ShoppingListIngredients recipe={recipe} showAddAll={false} showAddMeal={false} showMadeIt={false} isInModal={true} />
                          </div>
                        </ClickAwayListener>
                      </div>
                    </div>
                }
              </span>
            </div>
            <div className="card-cta">
              <div className={madeItClasses} onClick={this.setMadeIt}>
                <input type="checkbox" className="rounded-checkbox" onChange={this.setMadeIt} checked={meal[MADE_KEY]} />
                <div className="checkbox-icon" />
                <span className="greyscale-1 font-bold p3-text">{'Made It'}</span>
              </div>
              { showRating &&
                <div className="rate-action">
                  <StarRating recipe={recipe} onStarClick={this.openReviewStep} includeTracking={true} score={deepProperty(recipe, 'rating.myReview.rating', 0)} />
                </div>
              }
              { showEditModal &&
                <div className="edit-action" onClick={this.openManualEntryModal} role="button" title="Edit Recipe">
                  <Icon className="edit-icon p2-text greyscale-3" iconName="pencil" />
                  <span className="edit-text font-bold p3-text greyscale-1">{'Edit'}</span>
                </div>
              }
              <div role="button" className={mobileOptionsClasses} onClick={this.toggleMobileOptions}>
                {
                  !this.state.mobileOptionsExpanded &&
                  <Icon iconName="overflowVertical"/>
                }
                {
                  this.state.mobileOptionsExpanded &&
                  <div className={'mobile-options-delete'}>
                    <Icon iconName="circleXSmall" id={mobileDeleteId}/>
                    <div> {'DELETE'} </div>
                  </div>
                }
              </div>
            </div>
          </div>
      }
      { !tinyCardMode && cardState === cardStates.review &&
          <div className="card-content">
            <Review
              recipe={recipe}
              new={!myReview}
              review={myReview}
              alwaysOpen={true}
              initialStars={Number(this.state.clickedStarRating)}
              onCancelCallback={this.reviewCancelCallback}
              onSubmitCallback={this.reviewSubmitCallback}
              onDeleteCallback={this.reviewDeleteCallback}
            />
          </div>
      }
      { tinyCardMode && [cardStates.review, cardStates.submittedReview].indexOf(cardState) > -1 &&
          <React.Fragment>
            <div className="review-popup background-light">
              <div className="review-popup-wrapper">
                { cardState === cardStates.review &&
                    <React.Fragment>
                      <h3 className="popup-title font-bold primary-dark">{'Leave a review'}</h3>
                      <Review
                        recipe={recipe}
                        new={!myReview}
                        review={myReview}
                        alwaysOpen={true}
                        initialStars={Number(this.state.clickedStarRating)}
                        onCancelCallback={this.reviewCancelCallback}
                        onSubmitCallback={this.reviewSubmitCallback}
                        onDeleteCallback={this.reviewDeleteCallback}
                      />
                    </React.Fragment>
                }
                { cardState === cardStates.submittedReview && submittedReviewBlurb }
              </div>
              <Icon role="button" title="close review" onClick={this.reviewCancelCallback} iconName="xLarge" className="review-popup-close" />
            </div>
            <div className="popup-overlay" />
          </React.Fragment>
      }
      { !tinyCardMode && cardState === cardStates.submittedReview && submittedReviewBlurb }
      { cardState === cardStates.initial &&
          <span role="button" title="Delete meal from meal plan" className="delete-card" onClick={this.deleteFromIcon}>
            <Icon iconName="circleXMedium" className="greyscale-3" />
          </span>
      }
    </div>
  );
}
}

MealPlanRecipeCard.propTypes = {
  deleteMeals: YummlyPropTypes.action,
  isInTray: YummlyPropTypes.bool,
  logDDEEvent: YummlyPropTypes.action,
  logEvent: YummlyPropTypes.action,
  meal: YummlyPropTypes.meal,
  popupOpen: YummlyPropTypes.bool,
  recipe: YummlyPropTypes.recipe,
  setPopup: YummlyPropTypes.action,
  showingModal: YummlyPropTypes.action,
  toggleIngredientsListModal: YummlyPropTypes.action,
  windowDetails: YummlyPropTypes.windowDetails,
  toggleManualEntryModal: YummlyPropTypes.action,
  toggleRemoveModal: YummlyPropTypes.action,
  setMadeIt: YummlyPropTypes.action,
};

const mapStateToProps = (state, ownProps) => {
  const newProps = {
    shoppingListItems: state.shoppingList.data.items,
    windowDetails: state.windowDetails,
    isGoogleLogin: Boolean(deepProperty(state.auth.currentUser, '.identities.google.id')),
  };

  if (ownProps.meal[RECIPE_DATA_KEY]) {
    const recipe = deepProperty(ownProps.meal, `${RECIPE_DATA_KEY}.recipeInfo.recipe`, {});

    newProps.recipe = recipe;
  }

  return newProps;
};

const mapDispatchToProps = {
  deleteMeals,
  logDDEEvent,
  logEvent,
  toggleIngredientsListModal,
  showingModal,
  setMadeIt,
  toggleManualEntryModal,
  toggleRemoveModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanRecipeCard);
