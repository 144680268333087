import React, { Component } from 'react';
import { connect } from 'react-redux';

import { icons } from 'constants/FontFaceCharacters';
import { bindInstance } from 'util/dev';
import cookie from 'util/cookies';
import { SL_BUY_INGREDIENT_TOOLTIP_COOKIE } from 'constants/Cookies';
import { logEvent } from 'actions/mixpanel';
import { TRACKING } from 'constants/Tracking';
import { SHOW_SL_BUY_INGREDIENT_TOOLTIP } from 'constants/index';
import { updateTooltip } from 'actions/Tooltip';
import { deepProperty } from 'util/object';
import { currentUserSelector } from 'selectors/auth';

export class ShoppingListBuyIngredientTooltip extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      initialState: {
        showTooltip: !props.showSLPlanAndShopTooltip && !props.showSLAddIngredientTooltip && !cookie.get(SL_BUY_INGREDIENT_TOOLTIP_COOKIE),
      },
    });
    this.isTracked = false;
  }
  componentDidMount() {
    const isSLempty = !Boolean(deepProperty(this.props.shoppingList, 'data.items').length);
    if (isSLempty) {
      return;
    }
    if (!this.isTracked) {
      this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.SHOPPING_LIST_BUY_INGREDIENT_TOOLTIP,
      });
      this.isTracked = true;
    }
  }
  componentDidUpdate(prevProps) {
    const isSLempty = !Boolean(deepProperty(this.props.shoppingList, 'data.items').length);
    const { isLoggedIn: currLoggedIn } = this.props;
    const prevLoggedIn = prevProps.isLoggedIn;
    if (isSLempty) {
      return;
    } else if (!this.isTracked && !isSLempty) {
      this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.SHOPPING_LIST_BUY_INGREDIENT_TOOLTIP,
      });
      this.isTracked = true;
    }

    if (currLoggedIn && prevLoggedIn !== currLoggedIn && !this.isTracked) {
      this.props.logMixpanelEvent(TRACKING.PROMPT_VIEW, {
        'Prompt Type': TRACKING.SHOPPING_LIST_BUY_INGREDIENT_TOOLTIP,
      });
      this.isTracked = true;
    }
  }
  componentWillUnmount() {
    const isSLempty = !Boolean(deepProperty(this.props.shoppingList, 'data.items').length);
    if (!this.props.showSLPlanAndShopTooltip && !this.props.showSLAddIngredientTooltip && !isSLempty) {
      this.setCookieAndStore();
    }
  }

  onClose() {
    this.setState({
      showTooltip: false,
    });
    cookie.set(SL_BUY_INGREDIENT_TOOLTIP_COOKIE, true);
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.SHOPPING_LIST_BUY_INGREDIENT_TOOLTIP,
      'Prompt Action': TRACKING.CLOSE_DIALOG_BOX,
    });
  }

  onGotIt() {
    this.setState({
      showTooltip: false,
    });
    cookie.set(SL_BUY_INGREDIENT_TOOLTIP_COOKIE, true);
    this.props.logMixpanelEvent(TRACKING.PROMPT_CLICK, {
      'Prompt Type': TRACKING.SHOPPING_LIST_BUY_INGREDIENT_TOOLTIP,
      'Prompt Action': TRACKING.GOT_IT,
    });
  }

  setCookieAndStore() {
    this.props.updateTooltip(SHOW_SL_BUY_INGREDIENT_TOOLTIP, false);
    cookie.set(SL_BUY_INGREDIENT_TOOLTIP_COOKIE, true);
  }

  render() {
    const isSLempty = !Boolean(deepProperty(this.props.shoppingList, 'data.items').length);
    if (isSLempty || this.props.showSLAddIngredientTooltip || !this.state.showTooltip) {
      return null;
    }
    return (
      <div className="shopping-list-buy-ingredient-tooltip">
        <span className="new-flag font-bold greyscale-3">{'GO SHOPPING'}</span>
        <h5 className="tooltip-title font-bold h5-text">{'Order groceries online'}</h5>
        <p className="p3-text greyscale-2">{'Get ingredients (and anything else you need) delivered from a local store.'}</p>
        <span
          className="y-icon close-btn greyscale-3"
          data-icon={icons.xLarge}
          onClick={this.onClose}
        />
        <span className="font-bold p3-text primary-teal got-it-btn" onClick={this.onGotIt} >{'Got it'}</span>
      </div>
    );
  }
}

ShoppingListBuyIngredientTooltip.propTypes = {
  logMixpanelEvent: YummlyPropTypes.action,
  updateTooltip: YummlyPropTypes.action,
  showSLPlanAndShopTooltip: YummlyPropTypes.bool,
  showSLAddIngredientTooltip: YummlyPropTypes.bool,
  shoppingList: YummlyPropTypes.shoppingList,
  isLoggedIn: YummlyPropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    shoppingList: state.shoppingList,
    showSLPlanAndShopTooltip: state.tooltip.showSLPlanAndShopTooltip,
    showSLAddIngredientTooltip: state.tooltip.showSLAddIngredientTooltip,
    isLoggedIn: Boolean(currentUserSelector(state)),
  };
};

const mapDispatchToProps = {
  logMixpanelEvent: logEvent,
  updateTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListBuyIngredientTooltip);
