import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { deepProperty } from 'util/object';
import AddIngredient from 'components/AddIngredient';
import { bindInstance } from 'util/dev';
import { getStringFunction } from 'util/localStrings';
import classNames from 'util/classNames';
import { connect } from 'react-redux';
import {
  addAllIngredientsFromRecipe,
  removeIngredientsFromRecipes,
  ADD_TYPE,
  DELETE_TYPE,
  SL_SCREEN_TYPE,
} from 'actions/ShoppingList';
import {
  compareIngredients,
} from 'util/shoppingList';
import Icon from 'components/Pasta/Icon';
import { mealItemsSelector } from 'selectors/mealPlanning';
import MadeItButton, { MADE_IT_BUTTON_TYPES } from 'components/MadeItButton';
import { DDE, TRACKING } from 'constants/Tracking';
import { MEAL_PLANNING_PAGE, RECIPE_PAGE } from 'util/location';
import { featureFlagSelector } from 'selectors/metadata';
import Basketful from 'components/Basketful';
import { BASKETFUL_ENTRY_POINTS } from 'actions/Basketful';
import { fetchMealPlan } from 'actions/MealPlanning';
import AddToPlanButton from 'components/AddToPlanButton';

const str = getStringFunction([
  {
    id: 'shoppingListIngredients.addAllSL',
    defaultMessage: 'Add All to Shopping List',
  },
  {
    id: 'shoppingListIngredients.removeAllSL',
    defaultMessage: 'Remove All from Shopping List',
  },
  {
    id: 'shoppingListIngredients.mealPlan.addAllSL',
    defaultMessage: 'Add to Shopping List',
  },
  {
    id: 'shoppingListIngredients.mealPlan.removeAllSL',
    defaultMessage: 'Remove from Shopping List',
  },
  {
    id: 'shoppingListIngredients.addMP',
    defaultMessage: 'Add to Meal Planner',
  },
  {
    id: 'shoppingListIngredients.removeMP',
    defaultMessage: 'Remove from Meal Planner',
  },
], 'shoppingListIngredients');

export class ShoppingListIngredients extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      throttle: {
        removeAllIngredientsHandler: 2000,
        addAllIngredientHandler: 2000,
      },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      fetchMealPlan,
      mealRecipes,
    } = this.props;
    const query = new URLSearchParams(location.search);

    // refetch all MP to show correct UI redirecting from loginMeal
    if (location && query.get('loginMeal') === '_' && prevProps.mealRecipes.length !== mealRecipes.length) {
      fetchMealPlan();
    }
  }


  addAllIngredientHandler() {
    const trackingProperties = {}, { isInModal, pageLocations } = this.props;
    trackingProperties[ADD_TYPE] = TRACKING.ALL_RECIPE_INGREDIENTS;
    if (isInModal) {
      trackingProperties[SL_SCREEN_TYPE] = TRACKING.MEAL_PLAN_MODAL_ADD_RECIPE_INGREDIENTS;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_ADD_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE_MODAL;
      trackingProperties[DDE.SHOPPING_LIST_ADD_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE_MODAL;
    } else if (pageLocations[MEAL_PLANNING_PAGE]) {
      trackingProperties[SL_SCREEN_TYPE] = TRACKING.MEAL_PLAN_PAGE_ADD_RECIPE_INGREDIENTS;
      trackingProperties[DDE.SHOPPING_LIST_ADD_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_ADD_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE;
    } else if (pageLocations[RECIPE_PAGE]) {
      trackingProperties[DDE.SHOPPING_LIST_ADD_TYPE] = DDE.RECIPE;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_ADD_TYPE] = DDE.RECIPE;
      trackingProperties[SL_SCREEN_TYPE] = TRACKING.RECIPE_DETAILS_PAGE;
    }
    this.props.addAllIngredientsFromRecipe(this.props.recipe, false, trackingProperties);
  }

  removeAllIngredientsHandler() {
    const trackingProperties = {}, { isInModal, pageLocations } = this.props;
    trackingProperties[DELETE_TYPE] = TRACKING.ALL_RECIPE_INGREDIENTS;

    if (isInModal) {
      trackingProperties[DELETE_TYPE] = TRACKING.MEAL_PLAN_MODAL_REMOVE_RECIPE_INGREDIENTS;
      trackingProperties[DDE.SHOPPING_LIST_REMOVE_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE_MODAL;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_REMOVE_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE_MODAL;
    } else if (pageLocations[MEAL_PLANNING_PAGE]) {
      trackingProperties[DELETE_TYPE] = TRACKING.MEAL_PLAN_PAGE_REMOVE_RECIPE_INGREDIENTS;
      trackingProperties[DDE.SHOPPING_LIST_REMOVE_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_REMOVE_TYPE] = DDE.MEAL_PLAN_LIST_RECIPE;
    } else if (pageLocations[RECIPE_PAGE]) {
      trackingProperties[DDE.SHOPPING_LIST_REMOVE_TYPE] = DDE.RECIPE;
      trackingProperties[DDE.SHOPPING_LIST_RECIPE_REMOVE_TYPE] = DDE.RECIPE;
      trackingProperties[SL_SCREEN_TYPE] = TRACKING.RECIPE_DETAILS_PAGE;
    }
    return this.props.removeIngredientsFromRecipes([this.props.recipe], trackingProperties);
  }

  render() {
    const {
      isAmp,
      pageLocations,
      recipe,
      recipeIngredients = {},
      showIngredients,
    } = this.props;

    const units = recipe.units && recipe.units.toLowerCase();
    const ingLines = deepProperty(recipe, `ingredientLines.${units}`, []);
    const hasAllIngredients = Object.keys(recipeIngredients).length === ingLines.length;
    const cls = classNames({
      'shopping-list-ingredients': true,
      'has-all-ingredients': hasAllIngredients,
      'has-all-ingredients-tray': hasAllIngredients && pageLocations[MEAL_PLANNING_PAGE],
    });

    let allIngredientsIcon, allIngredientsText;
    let handler;
    if (hasAllIngredients) {
      allIngredientsIcon = 'shopRemove';
      allIngredientsText = showIngredients
        ? str('removeAllSL')
        : str('mealPlan.removeAllSL');
      handler = this.removeAllIngredientsHandler;
    } else {
      allIngredientsIcon = 'shopAdd';
      allIngredientsText = showIngredients
        ? str('addAllSL')
        : str('mealPlan.addAllSL');
      handler = this.addAllIngredientHandler;
    }

    return (
      <div className={cls}>
        { showIngredients &&
          ingLines.map((line, index) => {
            const key = `${index}${line.ingredient.toLowerCase()}`;
            const inShoppingList = recipeIngredients[key] &&
              compareIngredients(recipeIngredients[key], line, recipe, index) !== false;
            let slIndex;
            if (inShoppingList) {
              slIndex = recipeIngredients[key].shoppingListIndex;
            }
            return <AddIngredient
              recipe={recipe}
              ingredientEntity={line}
              key={index}
              index={index}
              existsInShoppingList={inShoppingList}
              slIndex={slIndex}
              isAmp={isAmp}
            />;
          })
        }
        { this.props.showBasketful &&
          <Basketful recipe={recipe} entrypoint={BASKETFUL_ENTRY_POINTS.RECIPE_DETAILS_PAGE} />
        }
        { this.props.showMadeIt &&
          <MadeItButton recipe={recipe} type={MADE_IT_BUTTON_TYPES.BLOCK} />
        }
        {!isAmp && (
          <div className="recipe-manage-section">
            { this.props.showAddAll &&
              <div className="add-all-ingrs primary-dark p3-text cursor-pointer" role="button" title="Add all ingredients to shopping list" onClick={handler}>
                <Icon className="add-item-icon primary-teal" iconName={allIngredientsIcon} />
                <span className="add-item-text font-bold">{allIngredientsText}</span>
              </div>
            }
            {
              this.props.showAddMeal &&
              <AddToPlanButton recipe={recipe} />
            }
          </div>
        )}
      </div>
    );
  }
}

ShoppingListIngredients.propTypes = {
  addAllIngredientsFromRecipe: YummlyPropTypes.action,
  fetchMealPlan: YummlyPropTypes.action,
  isAmp: YummlyPropTypes.bool,
  isInModal: YummlyPropTypes.bool, // this refers to the ingredient popup modal
  location: YummlyPropTypes.location,
  mealRecipes: YummlyPropTypes.mealItems,
  pageLocations: YummlyPropTypes.pageLocations,
  recipe: YummlyPropTypes.recipe,
  recipeIngredients: YummlyPropTypes.recipeIngredients,
  removeIngredientsFromRecipes: YummlyPropTypes.action,
  showAddAll: YummlyPropTypes.bool,
  showAddMeal: YummlyPropTypes.bool,
  showBasketful: YummlyPropTypes.bool,
  showIngredients: YummlyPropTypes.bool,
  showMadeIt: YummlyPropTypes.bool,
};

ShoppingListIngredients.defaultProps = {
  showIngredients: true,
  showAddAll: true,
  showMadeIt: true,
  showAddMeal: true,
  mealRecipes: [],
};

const mapStateToProps = (state, ownProps) => {
  const props = {
    shoppingListItems: state.shoppingList.data.items,
    shoppingListViewType: state.shoppingList.viewType,
    mealRecipes: mealItemsSelector(state),
    showBasketful: featureFlagSelector(state)('show-basketful') && state.pageLocations[RECIPE_PAGE],
    pageLocations: state.pageLocations,
  };

  if (ownProps.recipe) {
    props.recipeIngredients = state.shoppingList.data.recipeIngredients[ownProps.recipe.id];
  } else {
    props.recipeIngredients = {};
  }
  return props;
};

const mapDispatchToProps = {
  addAllIngredientsFromRecipe,
  removeIngredientsFromRecipes,
  fetchMealPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShoppingListIngredients));
