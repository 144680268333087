import React, { Component } from 'react';

import CardAction from 'components/CardAction';
import IngredientLine from 'components/IngredientLine';
import { connect } from 'react-redux';
import {
  syncShoppingList,
  addShoppingRecipeInfo,
  setRemovedItemCount,
  setAddedItemCount,
  setDeletedItems,
  redirectToLogin,
  ADD_TYPE,
  SL_SCREEN_TYPE,
  NUM_ITEMS,
  NAME_ITEMS,
  CONTENT_IDS,
  CONTENT_IDS_REMOVED,
  DELETE_TYPE,
  NUM_ITEMS_REMOVED,
  NAME_ITEMS_REMOVED,
} from 'actions/ShoppingList';
import { setPostLoginActions } from 'actions/App';

import {
  constructShoppingListItem,
  CATEGORY_KEY,
  CATEGORY_ID_KEY,
  INGREDIENT_ID_KEY,
  ORDER_IN_RECIPE_KEY,
  RECIPE_URL_NAME_KEY,
  QUANTITY_NUMBER_KEY,
  RECIPE_SERVING_KEY,
} from 'util/shoppingList';

import { ITEM_DELETED } from 'constants/index';
import { SYNC_SHOPPING_LIST, MIXPANEL_EVENT } from 'constants/PostLoginActions';
import { bindInstance } from 'util/dev';
import classNames from 'util/classNames';
import { currentUserSelector } from 'selectors/auth';
import { logEvent } from 'actions/mixpanel';
import { TRACKING, DDE } from 'constants/Tracking';
import { firstCap } from 'util/string';
import { logDDEEvent } from 'actions/DDE';

class AddIngredient extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      throttle: {
        handleClick: 200,
      },
    });
  }

  handleClick(e) {
    e.preventDefault();

    const {
      amount,
      category,
      categoryId,
      ingredient,
      ingredientId,
      abbreviation,
      pluralAbbreviation,
    } = this.props.ingredientEntity;
    const {
      index,
      syncShoppingList,
      recipe = {},
      existsInShoppingList,
      addShoppingRecipeInfo,
      setAddedItemCount,
      setDeletedItems,
      setRemovedItemCount,
      isLoggedIn,
      redirectToLogin,
      shoppingList,
      slIndex,
    } = this.props;
    const shoppingListItems = shoppingList.data.items;
    const { displayServings = 1, servings = 1, id: recipeId } = recipe;
    const multiplier = displayServings && servings && displayServings / servings;

    if (existsInShoppingList) {
      const slItem = shoppingListItems[slIndex];
      const newItem = constructShoppingListItem({
        ...slItem,
        status: ITEM_DELETED,
      });

      const ingredientName = firstCap(newItem['description']);
      const mixpanelProps = {
        [DELETE_TYPE]: TRACKING.RECIPE_INGREDIENT,
        [SL_SCREEN_TYPE]: TRACKING.RECIPE_DETAILS_PAGE,
        [CONTENT_IDS_REMOVED]: [newItem['recipe-url-name']],
        [NAME_ITEMS_REMOVED]: ingredientName,
        [NUM_ITEMS_REMOVED]: 1,
        [RECIPE_SERVING_KEY]: displayServings,
      };
      this.props.logMixpanelEvent(TRACKING.SHOPPING_LIST_DELETE, mixpanelProps);

      this.props.logDDEEvent(DDE.SHOPPING_LIST_REMOVE_INGREDIENT, {
        'ingredient': ingredientName,
        'shopping_list_remove_type': 'recipe',
      });

      syncShoppingList([newItem]);
      setRemovedItemCount(1);
      setDeletedItems([newItem]);
    } else {
      const slItem = constructShoppingListItem({
        description: ingredient.toLowerCase(),
        [ORDER_IN_RECIPE_KEY]: index,
        [CATEGORY_KEY]: firstCap(decodeURIComponent(category)),
        [CATEGORY_ID_KEY]: categoryId,
        [QUANTITY_NUMBER_KEY]: Number((amount * multiplier).toFixed(2)),
        [RECIPE_URL_NAME_KEY]: recipeId,
        [INGREDIENT_ID_KEY]: ingredientId,
        [RECIPE_SERVING_KEY]: displayServings,
        'recipe-type': recipe.isCustomRecipe ? 'custom' : 'recipe',
        'unit': amount > 1 ? pluralAbbreviation : abbreviation,
      });

      const mixpanelEvent = {
        eventName: TRACKING.SHOPPING_LIST_ADD,
        properties: {
          [ADD_TYPE]: TRACKING.RECIPE_INGREDIENT,
          [NAME_ITEMS]: [firstCap(slItem['description'])],
          [NUM_ITEMS]: 1,
          [CONTENT_IDS]: [recipeId],
          [SL_SCREEN_TYPE]: TRACKING.RECIPE_DETAILS_PAGE,
          [TRACKING.NUM_SERVING_SIZE]: displayServings,
          [TRACKING.DEFAULT_SERVING_SIZE]: servings,
          [TRACKING.SERVING_SIZE_CHANGED]: displayServings !== servings,
        },
      };

      if (!isLoggedIn) {
        setPostLoginActions({
          actionType: SYNC_SHOPPING_LIST,
          parameters: [[slItem]],
        });
        setPostLoginActions({
          actionType: MIXPANEL_EVENT,
          parameters: [mixpanelEvent.eventName, mixpanelEvent.properties],
        });
        return redirectToLogin();
      }

      this.props.logMixpanelEvent(mixpanelEvent.eventName, mixpanelEvent.properties);

      this.props.logDDEEvent(DDE.SHOPPING_LIST_ADD_INGREDIENT, {
        'shopping_list_add_type': 'recipe',
        ingredient: firstCap(slItem['description']),
      });

      console.log('--- AddIngredient recipe', recipe); // eslint-disable-line
      addShoppingRecipeInfo(recipe);
      syncShoppingList([slItem]);
      setAddedItemCount(1);
    }
  }

  render() {
    const { ingredientEntity, isAmp, recipe, existsInShoppingList } = this.props;
    const cls = classNames({
      'add-ingredient': true,
      'show-add': !existsInShoppingList,
      'show-remove': existsInShoppingList,
    });

    return (
      <ul className={cls}>
        {!isAmp && <CardAction actionType={existsInShoppingList ? 'remove-ingredient' : 'add-ingredient'} onClick={this.handleClick} />}
        <IngredientLine {...ingredientEntity} data={recipe} />
      </ul>
    );
  }
}

AddIngredient.propTypes = {
  addShoppingRecipeInfo: YummlyPropTypes.action,
  existsInShoppingList: YummlyPropTypes.bool,
  index: YummlyPropTypes.number,
  ingredientEntity: YummlyPropTypes.ingredientEntity,
  isAmp: YummlyPropTypes.bool,
  isLoggedIn: YummlyPropTypes.bool,
  logDDEEvent: YummlyPropTypes.action,
  logMixpanelEvent: YummlyPropTypes.action,
  recipe: YummlyPropTypes.recipe,
  redirectToLogin: YummlyPropTypes.action,
  setAddedItemCount: YummlyPropTypes.action,
  setDeletedItems: YummlyPropTypes.action,
  setRemovedItemCount: YummlyPropTypes.action,
  shoppingList: YummlyPropTypes.shoppingList,
  slIndex: YummlyPropTypes.shoppingListIndex,
  syncShoppingList: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    shoppingList: state.shoppingList,
    isLoggedIn: Boolean(currentUserSelector(state)),
  };
};

const mapDispatchToProps = {
  addShoppingRecipeInfo,
  redirectToLogin,
  setAddedItemCount,
  setRemovedItemCount,
  setDeletedItems,
  syncShoppingList,
  logMixpanelEvent: logEvent,
  logDDEEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredient);
