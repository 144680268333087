import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MIN_REVIEW_LENGTH, MAX_REVIEW_LENGTH } from 'constants/index';
import { str } from 'components/Review';
import {
  addReview,
  editReview,
  deleteReview,
  mpLogReviewError,
} from 'actions/Recipe';
import { bindInstance } from 'util/dev';
import Button from 'components/Button';

export class ReviewActions extends Component {
  constructor() {
    super(...arguments);
    bindInstance(this);
  }

  handleSubmitReview() {
    const {
      currentText,
      directionsReview,
      stars,
      review,
      recipe,
      setTypeState,
      addReview,
      handleClickClose,
      setErrorState,
      mpLogReviewError,
    } = this.props;
    const reviewState = this.props.new ? 'Add' : 'Edit';
    const reviewText = (currentText || '').trim();

    if (reviewText.length < MIN_REVIEW_LENGTH) {
      const lengthError =
        reviewText.length === 0 ? 'No text' : 'Not enough text';
      setErrorState(str('lengthError'));
      mpLogReviewError(review, recipe, reviewState, lengthError);
    } else if (reviewText.length > MAX_REVIEW_LENGTH) {
      setErrorState(str('lengthMaxError'));
      mpLogReviewError(review, recipe, reviewState, 'Max Length');
    } else if (stars === 0) {
      setErrorState(str('ratingError'));
      mpLogReviewError(review, recipe, reviewState, 'No stars');
    } else {
      if (review) {
        this.props.editReview(
          review.id,
          recipe,
          reviewText,
          stars,
          (recipe.reviews || []).length
        );
        setTypeState('text');
      } else {
        addReview(recipe, reviewText, stars);
        if (directionsReview) {
          handleClickClose();
        }
      }
      if (this.props.onSubmitCallback) {
        this.props.onSubmitCallback();
      }

      if (this.props.openCallback) {
        this.props.openCallback(false);
      }
    }
  }

  handleDeleteReview() {
    const { review, recipe } = this.props;
    if (review) {
      this.props.deleteReview(review.id, recipe);
    }
    if (this.props.onDeleteCallback) {
      this.props.onDeleteCallback();
    }

    if (this.props.openCallback) {
      this.props.openCallback(false);
    }
  }

  handleCloseReview() {
    this.props.handleClickClose();
    if (this.props.onCancelCallback) {
      this.props.onCancelCallback();
    }
  }

  render() {
    return (
      <div className="review-actions">
        <Button
          className="btn-primary submit-button"
          onClick={this.handleSubmitReview}
        >
          {'Submit'}
        </Button>
        <Button
          className="btn-wire cancel-button"
          onClick={this.handleCloseReview}
        >
          {'Cancel'}
        </Button>
        {!this.props.new && (
          <Button
            className="btn-wire delete-button"
            onClick={this.handleDeleteReview}
          >
            {'Delete'}
          </Button>
        )}
      </div>
    );
  }
}

ReviewActions.propTypes = {
  new: YummlyPropTypes.bool,
  currentText: YummlyPropTypes.string,
  stars: YummlyPropTypes.number,
  directionsReview: YummlyPropTypes.bool,
  review: YummlyPropTypes.review,
  recipe: YummlyPropTypes.recipe,
  addReview: YummlyPropTypes.action,
  deleteReview: YummlyPropTypes.action,
  editReview: YummlyPropTypes.action,
  handleClickClose: YummlyPropTypes.action,
  setErrorState: YummlyPropTypes.action,
  setTypeState: YummlyPropTypes.action,
  mpLogReviewError: YummlyPropTypes.action,
  openCallback: YummlyPropTypes.func,
  onSubmitCallback: YummlyPropTypes.func,
  onDeleteCallback: YummlyPropTypes.func,
  onCancelCallback: YummlyPropTypes.func,
};

const mapStateToProps = () => {
    return {};
  },
  mapDispatchToProps = {
    addReview,
    editReview,
    deleteReview,
    mpLogReviewError,
  };

export default connect(mapStateToProps, mapDispatchToProps)(ReviewActions);
