import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/Pasta/Button';
import moment from "moment";
import DateToolbar from 'components/Pasta/DateToolbar';
import { MEAL_PLANNER_TAB_IDS,
  addAppointmentRecipesToShoppingList,
  removeAllMealsFromShoppingList,
  toggleManualEntryModal,
  toggleScheduleModal,
  toggleMealPlanDiscoveryModal,
  updateSelectedAppointmentDate } from 'actions/MealPlanning';
import { selectedAppointmentDateSelector,
  selectedAppointmentDateRangeSelector,
  unscheduledAppointmentsSelector,
  appointmentsForSelectedDateRangeSelector,
  appointmentsForSelectedDateSelector } from 'selectors/mealPlanning';
import { friendlyDate } from 'util/timestamp';
import { showPopupMenu, toggleMealPlanClearModal } from 'actions/App';
import { pstIcons } from 'constants/FontFaceCharacters';
import { appointmentsInWeekFlattened } from 'util/mealPlanning';
import { MEAL_PLANNING_ROUTE, MEAL_PLANNING_TYPE } from 'constants/routes';
import classNames from 'util/classNames';

const MealPlanSchedulerToolbar = ({
  selectedAppointmentDate,
  selectedAppointmentDateRange,
  activeTabID,
  updateSelectedAppointmentDate,
  toggleMealPlanClearModal,
  showPopupMenu,
  unscheduledAppointments,
  dayAppointments,
  weekAppointments,
  addAppointmentRecipesToShoppingList,
}) => {
  const formattedStartDate = moment(selectedAppointmentDateRange?.start)?.format("MMM D");
  const formattedEndDate = moment(selectedAppointmentDateRange?.end)?.format("MMM D");

  const dateText = activeTabID === MEAL_PLANNER_TAB_IDS.DAY
    ? friendlyDate(selectedAppointmentDate)
    : formattedStartDate + " - " + formattedEndDate;

  function moveSelectedDate(direction) {
    const currentSelectedDate = new Date(selectedAppointmentDate);
    if (activeTabID === MEAL_PLANNER_TAB_IDS.DAY) {
      currentSelectedDate.setDate((currentSelectedDate.getDate() + Number(direction)));
    } else {
      currentSelectedDate.setDate((currentSelectedDate.getDate() + 7 * direction));
    }
    updateSelectedAppointmentDate(currentSelectedDate);
  }

  function moveSelectedDateBackward() {
    moveSelectedDate(-1);
  }

  function moveSelectedDateForward() {
    moveSelectedDate(1);
  }

  function moveToSelectedDate(date) {
    const selectedDate = moment(date);
    updateSelectedAppointmentDate(selectedDate);
  }

  function onClickedClearRecipes() {
    toggleMealPlanClearModal(true);
  }


  function onClickedPrint() {
    const url = `${MEAL_PLANNING_ROUTE}?viewType=${MEAL_PLANNING_TYPE}&tab=${activeTabID}&date=${selectedAppointmentDate}#print`;
    window.open(url);
  }

  function onClickedAddAllToShoppingList() {
    let appointmentsToAddRecipesFor = unscheduledAppointments;
    switch (activeTabID) {
      case MEAL_PLANNER_TAB_IDS.DAY:
        appointmentsToAddRecipesFor = dayAppointments;
        break;
      case MEAL_PLANNER_TAB_IDS.UNSCHEDULED:
        appointmentsToAddRecipesFor = unscheduledAppointments;
        break;
      case MEAL_PLANNER_TAB_IDS.WEEK:
        appointmentsToAddRecipesFor = appointmentsInWeekFlattened(weekAppointments);
        break;

    }
    addAppointmentRecipesToShoppingList(appointmentsToAddRecipesFor);
  }

  const btnID = 'appt-options';

  function onClickedActions() {
    const popupOptions = [
      {
        text: 'Add All To Shopping List',
        iconCode: pstIcons.F0003_41B__Icon_shoppinglist_add,
        onClick: onClickedAddAllToShoppingList,
      },
      {
        text: 'Print',
        iconCode: pstIcons.F0003_409__Icon_print,
        onClick: onClickedPrint,
      },
      {
        text: 'Clear Recipes',
        iconColor: 'red',
        iconCode: pstIcons.F0003_025__Icon_bin,
        onClick: onClickedClearRecipes,
      },
    ];
    showPopupMenu({
      options: popupOptions,
      position: {
        id: btnID,
      },
    });
  }

  const toolbarClassNames = classNames({
    'meal-plan-scheduler-toolbar': true,
    'flex': true,
    'align-items-center': true,
    [activeTabID]: true,
  });

  return (
    <div className={toolbarClassNames}>
      {
        activeTabID !== MEAL_PLANNER_TAB_IDS.UNSCHEDULED
          ? <DateToolbar
            dateText={dateText}
            onNavLeft={moveSelectedDateBackward}
            onNavRight={moveSelectedDateForward}
            onDateSelected={moveToSelectedDate}
          />
          : unscheduledAppointments?.length > 0
            ? <div className="header">
              <div className="pst-h6">{`Ready to Schedule`}</div>
            </div> : <></>
      }
      {activeTabID !== MEAL_PLANNER_TAB_IDS.UNSCHEDULED || unscheduledAppointments?.length > 0
        ? <div id="appointment-options">
          <Button
            iconCode={pstIcons.F0003_013__Icon_overflow}
            contained={true}
            onClick={onClickedActions}
            id={btnID}
          />
        </div> : <></>}
    </div>
  );
};

MealPlanSchedulerToolbar.propTypes = {
  activeTabID: YummlyPropTypes.string,
  toggleMealPlanClearModal: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    activeTabID: state.mealPlanning.activeTabID,
    myCollections: state.myCollections,
    dayAppointments: appointmentsForSelectedDateSelector(state),
    weekAppointments: appointmentsForSelectedDateRangeSelector(state),
    unscheduledAppointments: unscheduledAppointmentsSelector(state),
    selectedAppointmentDate: selectedAppointmentDateSelector(state),
    selectedAppointmentDateRange: selectedAppointmentDateRangeSelector(state),
    recipeIngredients: state.shoppingList.data.recipeIngredients,
  };
};

const mapDispatchFromProps = {
  addAppointmentRecipesToShoppingList,
  removeAllMealsFromShoppingList,
  toggleMealPlanDiscoveryModal,
  toggleMealPlanClearModal,
  toggleManualEntryModal,
  toggleScheduleModal,
  updateSelectedAppointmentDate,
  showPopupMenu,
};

export default connect(mapStateToProps, mapDispatchFromProps)(MealPlanSchedulerToolbar);