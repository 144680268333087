import React, { useEffect } from 'react';
import { connect } from 'react-redux';


import { fetchMealPlan } from 'actions/MealPlanning';
import { logEvent } from 'actions/mixpanel';
import { setShoppingListViewType } from 'actions/ShoppingList';
import { syncShoppingList } from 'actions/ShoppingList';
import { toggleGlobalTray, toggleMealPlannerPromoModal } from 'actions/App';
import MealPlanning from 'bundles/mealPlanning/components/MealPlanning';
import ShoppingListAddIngredientTooltip from 'bundles/mealPlanning/components/ShoppingListAddIngredientTooltip';
import ShoppingListBuyIngredientTooltip from 'bundles/mealPlanning/components/ShoppingListBuyIngredientTooltip';

import { isLoggedInSelector } from 'selectors/auth';
import { hasMealPlannerAccess } from 'selectors/paywall';

const PlanAndShop = ({
  isLoggedIn,
  showSLPlanAndShopTooltip,
  showSLAddIngredientTooltip,
  showSLBuyIngredientTooltip,
  fetchMealPlan,
  syncShoppingList,
}) => {
  useEffect(() => {
    if (isLoggedIn) {
      fetchMealPlan();
      syncShoppingList([]);
    }
  }, []);
  return (
    <div className="plan-and-shop">
      <MealPlanning />
      { !showSLPlanAndShopTooltip && showSLAddIngredientTooltip && <ShoppingListAddIngredientTooltip /> }
      { !showSLPlanAndShopTooltip && !showSLAddIngredientTooltip && showSLBuyIngredientTooltip && <ShoppingListBuyIngredientTooltip /> }
    </div>
  );
};

PlanAndShop.propTypes = {
  showSLPlanAndShopTooltip: YummlyPropTypes.bool,
  showSLAddIngredientTooltip: YummlyPropTypes.bool,
  showSLBuyIngredientTooltip: YummlyPropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    shoppingList: state.shoppingList,
    showSLPlanAndShopTooltip: state.tooltip.showSLPlanAndShopTooltip,
    showSLAddIngredientTooltip: state.tooltip.showSLAddIngredientTooltip,
    showSLBuyIngredientTooltip: state.tooltip.showSLBuyIngredientTooltip,
    windowDetails: state.windowDetails,
    currentTrayTab: state.app.showGlobalTray.trayType,
    hasMealPlannerAccess: hasMealPlannerAccess(state),
  };
};

const mapDispatchToProps = {
  logMixpanelEvent: logEvent,
  setShoppingListViewType,
  toggleGlobalTray,
  toggleMealPlannerPromoModal,
  fetchMealPlan,
  syncShoppingList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanAndShop);
