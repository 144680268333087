import React, { Component } from 'react';

import { connect } from 'react-redux';
import { mealItemsSelector } from 'selectors/mealPlanning';
import { bindInstance } from 'util/dev';
import { getCollections as getMyCollections } from 'actions/MyCollections';
import { addAllMealsToShoppingList, removeAllMealsFromShoppingList, toggleManualEntryModal, toggleScheduleModal, toggleMealPlanDiscoveryModal, updateSelectedAppointmentDate } from 'actions/MealPlanning';
import MealPlanTabs from 'components/MealPlanTabs';
import { currentUserSelector } from 'selectors/auth';
import MealPlanAppointments from 'components/MealPlanAppointments/MealPlanAppointments';
import MealPlanSchedulerToolbar from 'components/MealPlanSchedulerToolbar/MealPlanSchedulerToolbar';

class MealPlanView extends Component {
  constructor(props) {
    super(props);
    bindInstance(this, {
      throttle: {
        'addAllMealsHandler': 1000,
        'removeAllMealsHandler': 1000,
      },
      initialState: {
        popupOpen: false,
      },
    });
  }

  componentDidMount() {
    if (!this.props.myCollections?.length) {
      this.props.getMyCollections(this.props?.currentUser?.profileName);
    }
  }

  setPopupOpen(value) {
    this.setState({
      popupOpen: value,
    });
  }

  addAllMealsHandler() {
    if (!this.props.slSyncInProgress) {
      this.props.addAllMealsToShoppingList();
    }
  }

  removeAllMealsHandler() {
    if (!this.props.slSyncInProgress) {
      this.props.removeAllMealsFromShoppingList();
    }
  }

  render() {
    return (
      <div className="meal-plan-view meal-planning-spacing">
        <MealPlanTabs/>
        <MealPlanSchedulerToolbar/>
        <MealPlanAppointments/>
      </div>
    );
  }
}

MealPlanView.propTypes = {
  currentUser: YummlyPropTypes.objectOrString,
  myCollections: YummlyPropTypes.myCollections,
  addAllMealsToShoppingList: YummlyPropTypes.action,
  removeAllMealsFromShoppingList: YummlyPropTypes.action,
  slSyncInProgress: YummlyPropTypes.bool,
  getMyCollections: YummlyPropTypes.action,
};

const mapStateToProps = (state) => {
  return {
    activeTabID: state.mealPlanning.activeTabID,
    myCollections: state.myCollections,
    currentUser: currentUserSelector(state),
    mealItems: mealItemsSelector(state),
    recipeIngredients: state.shoppingList.data.recipeIngredients,
    shoppingListItems: state.shoppingList.data.items,
    slSyncInProgress: state.shoppingList.syncingShoppingList,
  };
};

const mapDispatchFromProps = {
  addAllMealsToShoppingList,
  removeAllMealsFromShoppingList,
  toggleMealPlanDiscoveryModal,
  toggleManualEntryModal,
  toggleScheduleModal,
  getMyCollections,
  updateSelectedAppointmentDate,
};

export default connect(mapStateToProps, mapDispatchFromProps)(MealPlanView);
