import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from 'components/Pasta/Button';
import { YYYY_MM_DD_DateFormat, shortMinutes } from 'util/timestamp';
import { removeItems } from 'actions/ShoppingList';
import { recipeViewSelector } from 'selectors/shoppingList';
import { addAppointmentRecipesToShoppingList,
  deleteAppointments,
  updateEditScheduleViewAppointmentDate,
  updateSelectedAppointment,
  updateSelectedRecipesToSchedule,
  updateSelectedAppointmentDate,
  updateAppointmentDatesToSchedule,
  scheduleSelectedRecipes,
  updateSelectedAppointmentSchedule,
  deleteMeals } from 'actions/MealPlanning';
import { ITEM_TYPE_KEY } from 'constants/MealPlanning';
import { toggleScheduleModal, toggleEditScheduleModal, toggleCookTimeModal } from "actions/MealPlanning";
import moment from 'moment';
import { pstIcons } from 'constants/FontFaceCharacters';
import { extractAppointmentDisplayInfo } from "util/mealPlanning";
import { convertTotalTime } from 'util/number';
import { selectedAppointmentDateSelector } from 'selectors/mealPlanning';
import MealPlannerCard from 'components/Pasta/MealPlannerCard';
import Switch, { SWITCH_TYPES } from 'components/Pasta/Switch/Switch';
import { showPopupMenu } from "actions/App";
import { FIELD_MEAL_PLAN_APPOINTMENT_START_DATE } from 'selectors/mealPlanning';

const MealPlanAppointmentCard = ({
  recipeData,
  appointmentData,
  manualEntryData,
  shoppingListItem,
  hasAllIngredients,
  selectable,
  hideOptions,
  selectedRecipesToSchedule,
  updateSelectedRecipesToSchedule,
  updateEditScheduleViewAppointmentDate,
  updateSelectedAppointmentSchedule,
  updateAppointmentDatesToSchedule,
  updateSelectedAppointment,
  addAppointmentRecipesToShoppingList,
  removeItems,
  selectedDate,
  deleteAppointments,
  deleteMeals,
  toggleCookTimeModal,
  toggleEditScheduleModal,
  showPopupMenu,
  preventNav,
  history,
}) => {

  let recipeTitle,
    recipeNote,
    recipeID,
    recipeCookDuration,
    recipeCookTime,
    recipeDisplayServings,
    recipeVersionID,
    recipeItemType,
    recipeLinkURL,
    recipeIMGURL,
    appointmentID,
    legacyMealPlanItemID;

  if (appointmentData) {
    ({
      recipeTitle,
      recipeIMGURL,
      recipeCookTime,
      recipeNote,
      recipeLinkURL,
      recipeCookDuration,
      appointmentID,
    } = extractAppointmentDisplayInfo(appointmentData));
  }

  if (manualEntryData) {
    recipeTitle = manualEntryData.title;
    recipeNote = manualEntryData.note;
    legacyMealPlanItemID = manualEntryData.id;
    recipeLinkURL = manualEntryData.url;
  }

  if (recipeData) {
    recipeTitle = recipeData?.displayName;
    const recipeInfo = recipeData?.recipeInfo;
    const recipeDetails = recipeInfo?.recipe;
    recipeID = recipeDetails?.id;
    recipeVersionID = recipeDetails?.versionId;
    recipeDisplayServings = recipeDetails?.displayServings;
    recipeCookDuration = convertTotalTime(recipeDetails?.totalTimeSec);
    recipeIMGURL = recipeDetails?.image;
    recipeItemType = 'recipe';
  }

  const scheduledStartDate = appointmentData?.[FIELD_MEAL_PLAN_APPOINTMENT_START_DATE];
  const isScheduled = Boolean(scheduledStartDate);

  const isSelected = Boolean(selectedRecipesToSchedule?.find((recipe) => recipe.id === recipeID));
  const btnID = `mp-option-btn-${appointmentID}`;

  const popupOptions = useMemo(() => {
    const inShoppingList = Boolean(hasAllIngredients);

    const options = [

    ];

    if (!manualEntryData) {
      options.push(
        {
          text: inShoppingList ? 'Remove from Shopping List' : 'Add To Shopping List',
          iconCode: inShoppingList ? pstIcons.F0003_41E__Icon_shoppinglist_remove : pstIcons.F0003_41B__Icon_shoppinglist_add,
          onClick: inShoppingList ? onClickedRemoveFromShoppingList : onClickedAddToShoppingList,
        });

      options.push({
        text: isScheduled ? 'Edit Schedule' : 'Schedule Recipe',
        iconCode: pstIcons.F0003_02A__Icon_calendar,
        onClick: onClickedEditSchedule,
      });
    }


    if (isScheduled) {
      options.push({
        text: 'Repeat Next Week',
        iconCode: pstIcons.F0003_030__Icon_repeat,
        onClick: onClickedRepeat,
      });
      options.push({
        text: 'Set Meal Time',
        iconCode: pstIcons.F0003_027__Icon_clock,
        onClick: onClickedCookTime,
      });
    }

    options.push({
      text: 'Remove From Meal Plan',
      iconCode: pstIcons.F0003_025__Icon_bin,
      iconColor: 'red',
      onClick: onClickedDeleteAppointment,
    });

    return options;
  }, [shoppingListItem, appointmentData, manualEntryData]);

  const chipConfigs = [];

  if (recipeCookTime && appointmentID) {
    const now = moment().format(YYYY_MM_DD_DateFormat);
    const cookMoment = moment(now + ' ' + recipeCookTime);

    chipConfigs.push({
      label: moment(cookMoment)?.format("LT"),
      icon: pstIcons.F0003_027__Icon_clock,
      bgColor: 'primary',
      chipClass: 'chip-cook-start-time',
    });
  }

  if (recipeCookDuration && recipeCookDuration.value && recipeCookDuration.unit) {
    chipConfigs.push({
      label: `${recipeCookDuration?.value} ${shortMinutes(recipeCookDuration?.unit)}`,
      icon: pstIcons.F0003_023__Icon_time_elapsed,
      bgColor: 'dark',
      chipClass: 'chip-cook-duration',
    });
  }


  function toggleIsSelected() {
    const newSelected = isSelected
      ? [...selectedRecipesToSchedule].filter((scheduledRecipe) => scheduledRecipe.id !== recipeID)
      : [...selectedRecipesToSchedule, {
        id: recipeID,
        servingSize: recipeDisplayServings,
        versionID: recipeVersionID,
        [ITEM_TYPE_KEY]: recipeItemType,
      }];
    updateSelectedRecipesToSchedule(newSelected);
  }

  function deleteAppointment() {
    if (appointmentID) {
      deleteAppointments([{
        id: appointmentID,
      }]);
    }
    if (legacyMealPlanItemID) {
      deleteMeals({
        meals: [manualEntryData],
      });
    }
  }

  function toggleActionsPopup(e) {
    e.preventDefault();
    e.stopPropagation();
    showPopupMenu({
      options: popupOptions,
      position: {
        id: btnID,
      },
    });
  }

  function onClickedCookTime() {
    updateSelectedAppointment(appointmentID);
    toggleCookTimeModal(true);
  }

  function onClickedRemoveFromShoppingList() {
    removeItems(shoppingListItem);
  }

  function onClickedAddToShoppingList() {
    addAppointmentRecipesToShoppingList([appointmentData]);
  }

  function onClickedEditSchedule() {
    updateSelectedAppointment(appointmentID);
    updateEditScheduleViewAppointmentDate(selectedDate);
    toggleEditScheduleModal(true);
  }

  function onClickedDeleteAppointment() {
    deleteAppointment();
  }

  function onClickedRepeat() {
    updateSelectedAppointment(appointmentID);
    const nextWeekDate = moment(scheduledStartDate).add(7, 'd').format(YYYY_MM_DD_DateFormat);
    updateAppointmentDatesToSchedule([nextWeekDate]);
    updateSelectedAppointmentSchedule();
  }

  function onClickCard(e) {
    if (!preventNav) {
      const btnClicked = e.target.closest(".pst-btn-icon");
      const isExternal = recipeLinkURL?.search("http") !== -1;

      if (!btnClicked && recipeLinkURL) {
        if (isExternal) {
          window.open(recipeLinkURL);
        } else {
          history.push(`/recipe/${recipeLinkURL}`);
        }
      }
    }
  }

  const actionButtons = selectable
    ? <Switch
      type={SWITCH_TYPES.PLUS}
      onClick={toggleIsSelected}
      checked={isSelected}
    /> : (appointmentID || legacyMealPlanItemID) && !hideOptions
      ? <div className="relative-container">
        <Button
          contained={true}
          iconCode={pstIcons.F0003_013__Icon_overflow}
          onClick={toggleActionsPopup}
          id={btnID}
          small={true}
        />
      </div>
      : null;

  return <MealPlannerCard
    title={recipeTitle}
    bodyText={recipeNote}
    imgURL={recipeIMGURL}
    chipsConfigs={chipConfigs}
    actionButtons={actionButtons}
    onClick={onClickCard}
  />;
};

MealPlanAppointmentCard.propTypes = {
  recipeData: YummlyPropTypes.objectOrString,
  appointmentData: YummlyPropTypes.objectOrString,
  selectable: YummlyPropTypes.bool,
  selectedRecipesToSchedule: YummlyPropTypes.arrayOfObjects,
  updateSelectedRecipesToSchedule: YummlyPropTypes.action,
  updateEditScheduleViewAppointmentDate: YummlyPropTypes.action,
  shoppingListItem: YummlyPropTypes.objectOrString,
  hasAllIngredients: YummlyPropTypes.bool,
  deleteAppointments: YummlyPropTypes.action,
  selectedDate: YummlyPropTypes.objectOrString,
  updateSelectedAppointment: YummlyPropTypes.action,
  toggleCookTimeModal: YummlyPropTypes.action,
  toggleEditScheduleModal: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
  hideOptions: YummlyPropTypes.bool,
  preventNav: YummlyPropTypes.bool,
};


const mapStateToProps = (state, { appointmentData }) => {
  const recipeData = appointmentData?.['recipe-data'];
  const apptRecipeID = recipeData?.id;
  const recipeDetails = recipeData?.recipeInfo?.recipe;
  const recipeIngredients = state?.shoppingList?.data?.recipeIngredients?.[apptRecipeID];
  const shoppingListItem = apptRecipeID && recipeViewSelector(state)[apptRecipeID];
  let hasAllIngredients = false;

  const units = recipeDetails?.units && recipeDetails.units.toLowerCase();

  if (units && recipeIngredients) {
    const ingLines = recipeDetails?.ingredientLines?.[units] || [];
    hasAllIngredients = Object.keys(recipeIngredients).length === ingLines.length;
  }

  return {
    shoppingListItem,
    hasAllIngredients,
    collection: state.collection,
    selectedRecipesToSchedule: state.mealPlanning.appointments.selectedRecipesToSchedule,
    selectedDate: selectedAppointmentDateSelector(state),
  };
};

const mapDispatchToProps = {
  deleteMeals,
  toggleScheduleModal,
  toggleCookTimeModal,
  toggleEditScheduleModal,
  addAppointmentRecipesToShoppingList,
  updateSelectedAppointmentSchedule,
  scheduleSelectedRecipes,
  removeItems,
  updateSelectedRecipesToSchedule,
  updateSelectedAppointmentDate,
  deleteAppointments,
  updateSelectedAppointment,
  updateEditScheduleViewAppointmentDate,
  updateAppointmentDatesToSchedule,
  showPopupMenu,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MealPlanAppointmentCard));
