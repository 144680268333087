import React from 'react';

import classNames from 'util/classNames';
import Icon from 'components/Pasta/Icon';
import { connect } from 'react-redux';
import { logEvent } from 'actions/mixpanel';
import { logDDEEvent } from 'actions/DDE';
import { toggleManualEntryModal, setMadeIt, deleteMeals } from 'actions/MealPlanning';
import { DDE, TRACKING } from 'constants/Tracking';

function MealPlanManualCard(props) {
  function setMadeIt(e) {
    e.preventDefault();

    props.setMadeIt(props.meal);
  }

  function deleteCard(e) {
    e.preventDefault();
    e.stopPropagation();
    const trackingProps = {
      ddeRemoveType: DDE.MEAL_PLAN_LIST,
      mpRemoveType: TRACKING.MEAL_PLAN_TYPE_PAGE,
    };

    props.deleteMeals({
      meals: [props.meal],
      trackingProps,
    });
  }

  function openManualEntryModal() {
    props.toggleManualEntryModal(true, props.meal);
  }

  const meal = props.meal;

  const madeItClasses = classNames({
    'made-it-wrapper cursor-pointer': true,
    'is-made': meal['is-made'],
  });

  return (
    <div className="meal-plan-manual-card">
      <p className="manual-card-title p1-text font-bold primary-dark">{meal.title}</p>
      <p className="manual-card-note p1-text greyscale-1 font-normal">{meal.note}</p>
      <div className="card-interactions">
        <div className={madeItClasses} onClick={setMadeIt}>
          <input type="checkbox" className="rounded-checkbox" onChange={setMadeIt} checked={meal['is-made']} />
          <div className="checkbox-icon" />
          <span className="greyscale-1 font-bold p3-text">{'Made It'}</span>
        </div>
        <div className="edit-card" onClick={openManualEntryModal} role="button">
          <Icon className="edit-icon p2-text greyscale-3" iconName="pencil" />
          <span className="edit-text font-bold p3-text greyscale-1">{'Edit'}</span>
        </div>
      </div>
      <Icon className="meal-plan-delete" iconName="circleXSmall" onClick={deleteCard} />
    </div>
  );
}

MealPlanManualCard.propTypes = {
  meal: YummlyPropTypes.meal,
  setMadeIt: YummlyPropTypes.action,
  deleteMeals: YummlyPropTypes.action,
  toggleManualEntryModal: YummlyPropTypes.action,
};

const mapDispatchToProps = {
  logDDEEvent,
  logEvent,
  toggleManualEntryModal,
  setMadeIt,
  deleteMeals,
};

export default connect(null, mapDispatchToProps)(MealPlanManualCard);
